var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button\n  id='databases-button'\n  type='button'\n  aria-expanded='false'\n  aria-haspopup='true'\n  @click='isOpen = !isOpen'\n  @click.outside='isOpen = false'\n  class='flex rounded-md texg-lg font-medium px-1 py-2 text-gray-300'\n  x-text='_(\"Databases\")'\n>\n</button>\n\n<div\n  id='databases_list'\n  role='menu'\n  aria-orientation='horizontal'\n  tabindex='-1'\n  x-show='isOpen'\n  x-transition:enter='transition ease-out duration-100 transform'\n  x-transition:enter-start='opacity-0 scale-95'\n  x-transition:enter-end='opacity-100 scale-100'\n  x-transition:leave='transition ease-in duration-75 transform'\n  x-transition:leave-start='opacity-100 scale-100'\n  x-transition:leave-end='opacity-0 scale-95'\n  class='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-white ring-opacity-5 cursor-pointer'\n  x-data='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"databases") || (depth0 != null ? lookupProperty(depth0,"databases") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"databases","hash":{},"data":data,"loc":{"start":{"line":26,"column":10},"end":{"line":26,"column":23}}}) : helper)))
    + "'\n>\n  <template x-for='db in databases'>\n    <a\n      :id='db[\"id\"].split(\" \").join(\"_\")'\n      role='menuitem'\n      tabindex='-1'\n      class='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:!text-elsred active:bg-gray-300'\n      :class='{\"pointer-events-none font-bold bg-gray-200\": db[\"selected\"] }'\n      x-text='_(db[\"text\"])'\n      @click='change_user_database(db[\"value\"], $event)'\n    >\n    </a>\n  </template>\n</div>";
},"useData":true});