/* Dizionario con tutti i testi nelle lingue disponibili */
/* ogni chiave corrisponde ad un identificativo del messaggio,
    ogni chiave ha come valore i messaggi nelle diverse lingue */

import { object_with_default } from './utils';

const translations = {
  //Navbar Menu Document
  Document: { en: 'Document', it: 'Documento' },
  Execute: { en: 'Execute', it: 'Esecuzione' },
  //fast comparison menu
  'Back to doc': { en: 'Back to doc', it: 'Torna al documento' },
  'Save as compare document': { en: 'Save as compare document', it: 'Salva come documento di confronto' },
  //Navbar New Material
  Formulation: { en: 'Formulation', it: 'Formulazione' },
  'Create Formulation': { en: 'Create Formulation', it: 'Crea Formulazione' },
  'Create Semi': { en: 'Create Semi', it: 'Crea Semilavorato' },
  'Document comparison': {
    en: 'Document comparison',
    it: 'Confronto di documenti',
  },
  Materials: { en: 'Materials', it: 'Materiali' },
  'Raw material': { en: 'Raw material', it: 'Materia prima' },
  MaterialRaw: { en: 'Raw', it: 'Materia prima' },
  'Raw materials': { en: 'Raw materials', it: 'Materie prime' },
  semi: { en: 'Semi finished', it: 'Semi lavorato' },
  'Semi finished': { en: 'Semi finished', it: 'Semilavorato' },
  MaterialSemi: { en: 'Semi finished', it: 'Semilavorato' },
  'Semi-finished': { en: 'Semi-finished', it: 'Semilavorato' },
  Mixes: { en: 'Mixes', it: 'Mix' },
  MaterialMix: { en: 'Mix', it: 'Mix' },
  Product: { en: 'Product', it: 'Prodotto' },
  MaterialProduct: { en: 'Product', it: 'Prodotto' },
  Oxide: { en: 'Oxide', it: 'Ossido' },
  MaterialOxide: { en: 'Oxide', it: 'Ossido' },
  Compound: { en: 'Compound', it: 'Composto' },
  MaterialCompound: { en: 'Compound', it: 'Composto' },
  Compounds: { en: 'Compounds', it: 'Composti' },
  Element: { en: 'Element', it: 'Elemento' },
  Elements: { en: 'Elements', it: 'Elementi' },
  MaterialElement: { en: 'Element', it: 'Elemento' },
  'Elements from compound': { en: 'Elements from compound', it: 'Elementi da composto' },
  'Elements from mix': { en: 'Elements from mix', it: 'Elementi da mix' },
  'Element from mix': { en: 'Element from mix', it: 'Elementi da mix' },
  'Elements from minerals': { en: 'Elements from minerals', it: 'Elementi da minerali' },
  Raw: { en: 'Raw', it: 'Materia Prima' },
  Oxides: { en: 'Oxides', it: 'Ossidi' },
  'Oxides from mix': { en: 'Oxides from mix', it: 'Ossidi da mix' },
  'Oxides from minerals': { en: 'Oxides from minerals', it: 'Ossidi da minerali' },
  'Oxides from compound': { en: 'Oxides from compound', it: 'Ossidi da composto' },
  'Compound from mix': { en: 'Compound from mix', it: 'Composti da mix' },
  Minerals: { en: 'Minerals', it: 'Minerali' },
  //Navbar New Analysis
  'Chemical (oxides)': { en: 'Chemical (oxides)', it: 'Chimica (ossidi)' },
  Mineralogical: { en: 'Mineralogical', it: 'Mineralogica' },
  Dilatometer: { en: 'Dilatometer', it: 'Dilatometro' },
  'Heating Microscope': { en: 'Heating Microscope', it: 'Microscopio riscaldante' },
  Microscope: { en: 'Microscope', it: 'Microscopio riscaldante' },
  Granulometry: { en: 'Granulometry', it: 'Granulometria' },
  Sample: { en: 'Sample', it: 'Campione' },
  Batch: { en: 'Batch', it: 'Lotto' },
  //Navbar New Entity
  Agent: { en: 'Agent', it: 'Operatore' },
  Operator: { en: 'Operator', it: 'Operatore' },
  Instrument: { en: 'Instrument', it: 'Strumento' },
  Company: { en: 'Company', it: 'Azienda' },
  Logistic: { en: 'Logistic', it: 'Logistica' },
  Place: { en: 'Place', it: 'Luogo' },
  'Use Case': { en: 'Use case', it: "Caso d'uso" },
  UseCase: { en: 'Use case', it: "Caso d'uso" },
  'Custom Label': { en: 'Custom label', it: 'Etichetta personalizzata' },
  CustomLabel: { en: 'Custom label', it: 'Etichetta personalizzata' },
  'Preparation Method': { en: 'Preparation method', it: 'Modalità di preparazione' },
  PreparationMethod: { en: 'Preparation method', it: 'Modalità di preparazione' },
  SearchGenome: { en: 'Genome Search', it: 'Ricerca in Genome' },
  'Physical Form': { en: 'Physical form', it: 'Caratteristiche fisiche' },
  PhysicalForm: { en: 'Physical form', it: 'Caratteristiche fisiche' },
  Tag: { en: 'Tag', it: 'Tag' },
  'Number Field': { en: 'Number field', it: 'Campo numerico' },
  FieldNumber: { en: 'Number field', it: 'Campo numerico' },
  'Table Field': { en: 'Table field', it: 'Campo tabellare' },
  FieldTabular: { en: 'Tabular field', it: 'Campo tabellare' },
  'Equation Field': { en: 'Equation field', it: 'Campo equazione' },
  FieldEquation: { en: 'Equation field', it: 'Campo equazione' },
  'Composition Field': { en: 'Composition field', it: 'Campo composizione' },
  FieldCompositionEquation: { en: 'Composition field equation', it: 'Equazione di composizione' },
  'Model Quartz': { en: 'Model quartz', it: 'Modello' },
  Plot: { en: 'Plot', it: 'Plot' },
  CTE: { en: 'CTE', it: 'CTE' },
  Quartz: { en: 'Quartz', it: 'Quartz' },
  Coupling: { en: 'Coupling', it: 'Coupling' },
  //Navbar Menus
  Databases: { en: 'Databases', it: 'Database' },
  Language: { en: 'Language', it: 'Lingua' },
  //search document div
  search: { en: 'search', it: 'cerca' },
  //search document buttons
  Apply: { en: 'Apply', it: 'Applica' },
  Clear: { en: 'Clear', it: 'Azzera' },
  Save: { en: 'Save', it: 'Salva' },
  Load: { en: 'Load', it: 'Carica' },
  //user menu
  User: { en: 'User', it: 'Utente' },
  'New user': { en: 'New user', it: 'Nuovo utente' },
  Import: { en: 'Import', it: 'Importa' },
  Help: { en: 'Help', it: 'Documentazione' },
  Logout: { en: 'Logout', it: 'Esci' },
  //genome search classes
  'No classes selected': { en: 'No classes selected', it: 'Nessun doctype selezionato' },
  //option table buttons
  'Create table': { en: 'Create table', it: 'Crea tabella' },
  'Load table': { en: 'Load table', it: 'Carica tabella' },
  Choose: { en: 'Choose', it: 'Seleziona' },
  //edit option buttons
  Cancel: { en: 'Cancel', it: 'Annulla' },
  'Empty table': { en: 'Empty table', it: 'Svuota tabella' },
  //report table buttons
  'Load all': { en: 'Load all', it: 'Carica tutto' },
  'Download table': { en: 'Download table', it: 'Scarica la tabella' },
  //toast time
  Now: { en: 'Now', it: 'Adesso' },
  Before: { en: 'Before', it: 'Prima' },
  //toast title
  Error: { en: 'Error', it: 'Errore' },
  //formulation toasts
  'target-skipped': {
    en: 'Some target components were skipped because missing in the available materials',
    it: 'Alcuni componenti della composizione non sono stati considerati perchè mancanti tra le risorse disponibili',
  },
  'Optimization failed': { en: 'Optimization failed', it: 'Ottimizzazione fallita' },
  'Document executed successfully': { en: 'Document executed successfully', it: 'Esecuzione riuscita' },
  'formulation-completed': { en: 'Optimization successfully completed', it: 'Ottimizzazione completata correttamente' },
  'missing-materials': {
    en: 'Cannot execute this optimization without available materials or target',
    it: 'Impossibile ottimizzare senza materiali da utilizzare o senza composizione obiettivo',
  },
  //glaze toasts
  'empty-list': {
    en: 'Empty documents list to change document type',
    it: 'Vuotare la lista dei documenti per cambiare il tipo',
  },
  //search document toast
  'search-document-saved': {
    en: 'Search document can now be loaded for search',
    it: 'Il documento di ricerca è ora disponibile per la ricerca',
  },
  'No document found': { en: 'No document found', it: 'Nessun documento trovato' },
  //comparison toast
  'comparison-duplicate': { en: 'Document is already in comparison', it: 'Il documento è già nel confronto' },
  '2-docs-needed': { en: 'At least 2 documents needed for comparison', it: 'Sono necessari almeno due documenti' },
  //document action toast
  'Edit saved': { en: 'Edit saved', it: 'Modifiche salvate' },
  'delete-document-impossible': {
    en: 'Delete document is impossible because is referenced by other documents',
    it: 'Impossibile eliminare il documento perchè è collegato ad altri documenti',
  },
  'cannot-edit': { en: 'Cannot edit, option is readonly', it: 'Questa opzione non può essere modificata' },
  'unsaved-edits': {
    en: 'Unsaved edits, apply/cancel before editing other values',
    it: 'Ci sono modifiche non salvate, applicare/annullare prima di eseguirne altre',
  },
  'Document creation deleted': { en: 'Document creation deleted', it: 'Creazione del documento annullata' },
  //
  'Cannot execute the document': { en: 'Cannot execute the document', it: 'Impossibile eseguire il documento' },
  'missing-document': { en: 'No document, showing home page', it: 'Documento mancante, ritorno alla pagina iniziale' },
  'no-rows': { en: 'No rows selected, please select one', it: 'Nessuna riga selezionata, selezionarne una' },
  'Value is out of range': { en: 'Value is out of range', it: "Un valore non rientra nell'intervallo" },
  //table edit toast
  'edit-mode-table': {
    en: 'Enable edit mode to edit the table',
    it: 'Abilita la modalità di modifica per modificare la tabella',
  },
  'materials-duplicate': {
    en: 'One of selected materials is already in the table',
    it: 'Uno dei materiali selezionati è già presente nella tabella',
  },
  'Already added to table': { en: 'Already added to table', it: 'Già aggiunto alla tabella' },
  //common toasts
  copied: { en: 'Copied. "Ctrl + V" to paste it', it: 'Copiato. appunti! Ctrl + V per incollare.' },
  'Deleted document': { en: 'Deleted document', it: 'Documento eliminato' },
  //dialog titles
  'Confirm restore': { en: 'Confirm restore', it: 'Conferma il ripristino' },
  'Confirm save': { en: 'Confirm save', it: 'Conferma del savataggio' },
  'Create document': { en: 'Create document', it: 'Crea documento' },
  //delete document dialog
  'confirm-delete': {
    en: 'The document and all his versions will be deleted, continue?',
    it: 'Il documento e tutte le sue versioni saranno eliminate, continuare?',
  },
  //Modals buttons
  Confirm: { en: 'Confirm', it: 'Conferma' },
  Close: { en: 'Close', it: 'Chiudi' },
  Remove: { en: 'Remove', it: 'Rimuovi' },
  Clone: { en: 'Clone', it: 'Duplica' },
  //Other
  'Available tests': { en: 'Available tests', it: 'Test effettuati' },
  'Cumulative Expenses': { en: 'Cumulative Expenses', it: 'Crediti complessivi usati' },
  //import page
  'File name:': { en: 'File name:', it: 'Nome del file:' },
  'Size:': { en: 'Size:', it: 'Dimensione:' },
  'Import files': { en: 'Import files', it: 'Importa file' },
  Files: { en: 'Files', it: 'File' },
  'Total size': { en: 'Total size', it: 'Dimensione totale' },
  'Drag and drop the files to upload in the area': {
    en: 'Drag and drop the files to upload in the area',
    it: 'Trascina e rilascia qui i file da caricare',
  },
  Upload: { en: 'Upload', it: 'Carica' },
  'Confirm upload': { en: 'Confirm upload', it: 'Conferma caricamento' },
  //import toasts
  'files-duplicate': {
    en: 'Cannot add two files with the same name',
    it: 'Impossibile caricare due file con lo stesso nome',
  },
  //comparison
  'added-to-comparison': {
    en: 'added to comparison, click a row in the table and add docs',
    it: 'aggiunto al confronto, click su una riga della tabella per aggiungere documenti',
  },
  'Select a document to remove': { en: 'Select a document to remove', it: 'Seleziona il documento da rimuovere' },
  'Show only different properties': {
    en: 'Show only different properties',
    it: 'Mostra solo le proprietà diverse',
  },
  'show other': { en: 'show other', it: 'mostra altri' },
  //Document menu titles
  'Execute document': { en: 'Execute document', it: 'Esegui documento' },
  'Confirm document creation': { en: 'Confirm document creation', it: 'Salva il nuovo documento' },
  'Abort document creation': { en: 'Abort document creation', it: 'Annulla creazione del documento' },
  'Export document': { en: 'Export document', it: 'Esporta documento' },
  'Save edits': { en: 'Save edits', it: 'Salva le modifiche' },
  'Cancel edits': { en: 'Cancel edits', it: 'Annulla le modifiche' },
  'Create a batch': { en: 'Create a batch', it: 'Crea un lotto' },
  'Hide style source': { en: 'Hide style source', it: 'Nascondi sorgente dello style' },
  'View style source': { en: 'View style source', it: 'Mostra sorgente dello style' },
  'Hide document source': { en: 'Hide document source', it: 'Nascondi sorgente del documento' },
  'View document source': { en: 'View document source', it: 'Mostra sorgente del documento' },
  'Clone document': { en: 'Clone document', it: 'Clona documento' },
  'Delete document': { en: 'Delete document', it: 'Elimina documento' },
  'Minimize document': { en: 'Minimize document', it: 'Mostra tabella di ricerca' },
  'Maximize document': { en: 'Maximize document', it: 'Mostra documento a schermo intero' },
  //Report menu titles
  'Download original file': { en: 'Download original file', it: 'Scarica file originale' },
  'List duplicates': { en: 'List duplicates', it: 'Elenca i duplicati' },
  'Find related tests': { en: 'Find related tests', it: 'Test collegati' },
  'Find tests with same thermal cycle': {
    en: 'Find tests with same thermal cycle',
    it: 'Test con ciclo termico in comune',
  },
  //hsm shapes
  'Samples images': { en: 'Samples images', it: 'Immagini dei campioni' },
  Storyboard: { en: 'Storyboard', it: 'Storyboard' },
  Show: { en: 'Show', it: 'Mostra' },
  Hide: { en: 'Hide', it: 'Nascondi' },
  'Sample deformation during test': {
    en: 'Sample deformation during test',
    it: 'Deformazione del campione durante la prova',
  },
  //user page
  'User Information': { en: 'User Information', it: 'Profilo Utente' },
  'Manage account information': { en: 'Manage account information', it: 'Modifica informazioni' },
  //manage userinfo buttons
  'Update password': { en: 'Update password', it: 'Aggiorna password' },
  'Reset value': { en: 'Reset value', it: 'Azzera valori' },
  //company page
  'Available credits:': { en: 'Available credits:', it: 'Crediti disponibili:' },
  'Last year expenses:': { en: 'Last year expenses:', it: "Spese dell'ultimo anno:" },
  'Overall expenses:': { en: 'Overall expenses:', it: 'Spese complessive:' },
  Update: { en: 'Update', it: 'Aggiorna' },
  'Licensed Instruments': { en: 'Licensed Instruments', it: 'Strumenti con licenza' },
  'Licensed Clients': { en: 'Licensed Clients', it: 'Client con licenza' },
  //option label titles
  'Click to edit': { en: 'Click to edit', it: 'Click per modificare il valore' },
  'Option is readonly': { en: 'Option is readonly', it: 'Il valore non può essere modificato' },
  'View table in full screen': { en: 'View table in full screen', it: 'Mostra tabella a schermo intero' },
  //login form
  'username or email address': { en: 'username or email address', it: 'nome utente o indirizzo email' },
  'email address': { en: 'Email address', it: 'Indirizzo email' },
  'New password': { en: 'New password', it: 'Nuova password' },
  'Confirm new password': { en: 'Confirm new password', it: 'Conferma nuova password' },
  'Confirm password': { en: 'Confirm password', it: 'Conferma password' },
  'Insert confirm password': { en: 'Insert confirm password', it: 'Inserisci conferma password' },
  'Insert captcha': { en: 'Insert captcha', it: 'Inserisci captcha' },
  'Log in': { en: 'Log in', it: 'Accedi' },
  'Forgot password': { en: 'Forgot password', it: 'Password dimenticata' },
  Register: { en: 'Register', it: 'Registrati' },
  //input placeholders
  'find document...': { en: 'find document...', it: 'cerca documento...' },
  'current password': { en: 'Current password', it: 'Password corrente' },
  //load search document
  'Choose a document to load:': { en: 'Choose a document to load:', it: 'Seleziona il documento da caricare:' },
  //table placeholder
  'No documents found': { en: 'No documents found', it: 'Nessun documento trovato' },
  //table row tooltip
  'Click to show document': { en: 'Click to show document', it: 'Click per mostrare il documento' },
  'Click to add to comparison': { en: 'Click to add to comparison', it: 'Click per aggiungere a confronto' },
  'Click to add': { en: 'Click to add', it: 'Click per aggiungere' },
  //dialog titles
  'Save search filters in a document': { en: 'Save search filters in a document', it: 'Salva il documento di ricerca' },
  'Load saved search document': { en: 'Load saved search document', it: 'Carica un documento di ricerca' },
  'Full stats': { en: 'Full stats', it: 'Informazioni complete' },
  //new document dialog
  'New document name:': { en: 'New document name:', it: 'Nome del nuovo documento:' },
  'invalid-name': { en: 'Invalid document name', it: 'Nome del documento non valido' },
  //invalid target toast
  'Destination table accepts only': { en: 'Destination table accepts only', it: 'La tabella accetta soltanto' },
  //empty name toast
  'Field cannot be empty': { en: 'Field cannot be empty', it: 'Il valore non può essere vuoto' },
  //new user form
  'Register new user': { en: 'Register new user', it: 'Registra un nuovo utente' },
  'Select all': { en: 'Select all', it: 'Seleziona tutto' },
  'Select none': { en: 'Select none', it: 'Deseleziona tutto' },
  'Select entries': { en: 'Select entries', it: 'Seleziona' },
  'Full text search': { en: 'Full text search', it: 'Ricerca full text' },
  Uses: { en: 'Uses', it: 'Usi' },
  Components: { en: 'Components', it: 'Componenti' },
  Fields: { en: 'Fields', it: 'Campi' },
  Versions: { en: 'Versions', it: 'Versioni' },
  Modified: { en: 'Modified', it: 'Modificato' },
  'Modified at:': { en: 'Modified at:', it: 'Data modifica:' },
  'Select period': { en: 'Select period', it: 'Periodo' },
  Date: { en: 'Date', it: 'Data' },
  Title: { en: 'Title', it: 'Titolo' },
  Instruments: { en: 'Instruments', it: 'Strumenti' },
  Duration: { en: 'Duration', it: 'Durata' },
  Temperature: { en: 'Temperature', it: 'Temperatura' },
  Rate: { en: 'Rate', it: 'Velocità' },
  'Sample name': { en: 'Sample name', it: 'Nome campione' },
  Serial: { en: 'Serial', it: 'Seriale' },
  Orphans: { en: 'Orphans', it: 'Orfani' },
  Only: { en: 'Only', it: 'Solo' },
  'Only versions': { en: 'Only versions', it: 'Solo versioni' },
  Include: { en: 'Include', it: 'Includi' },
  Classes: { en: 'Classes', it: 'Classi' },
  Tags: { en: 'Tags', it: 'Tag' },
  'Tags AND/OR': { en: 'Tags AND/OR', it: 'Tag AND/OR' },
  'Uses AND/OR': { en: 'Uses AND/OR', it: 'Usi AND/OR' },
  'Fields AND/OR': { en: 'Fields AND/OR', it: 'Campi AND/OR' },
  Other: { en: 'Other', it: 'Altro' },
  Material: { en: 'Material', it: 'Materiale' },
  '+ Material': { en: '+ Material', it: '+ Materiale' },
  Analysis: { en: 'Analysis', it: 'Analisi' },
  '+ Analysis': { en: '+ Analysis', it: '+ Analisi' },
  '+ Misc': { en: '+ Misc', it: '+ Misc' },
  Reports: { en: 'Reports', it: 'Report' },
  Models: { en: 'Models', it: 'Modelli' },
  '+ Model': { en: '+ Model', it: '+ Modello' },
  Comment: { en: 'Comment', it: 'Commento' },
  Name: { en: 'Name', it: 'Nome' },
  'Phone number': { en: 'Phone number', it: 'Telefono' },
  'Insert name': { en: 'Insert name', it: 'Inserisci nome' },
  'Full name': { en: 'Full name', it: 'Nome e cognome' },
  Details: { en: 'Details', it: 'Dettagli' },
  'Insert full name': { en: 'Insert full name', it: 'Inserisci nome e cognome' },
  'Company name': { en: 'Company name', it: 'Nome azienda' },
  'Last year expenses': { en: 'Last year expenses', it: 'Spese dello scorso anno' },
  'Overall expenses': { en: 'Overall expenses', it: 'Spese totali' },
  Clients: { en: 'Clients', it: 'Client' },
  Administrators: { en: 'Administrators', it: 'Amministratori' },
  'Available Credits': { en: 'Available Credits', it: 'Crediti' },
  'Insert company name': { en: 'Insert company name', it: 'Inserisci nome azienda' },
  Country: { en: 'Country', it: 'Paese' },
  'Insert country': { en: 'Insert country', it: 'Inserisci paese' },
  Alternatives: { en: 'Alternatives', it: 'Alternative' },
  'Report Sample nr.': { en: 'Report Sample nr.', it: 'Report, campione nr.' },
  'Composition: Mix': { en: 'Composition: Mix', it: 'Composizione: Mix' },
  'Composition: Semi/Raw': { en: 'Composition: Semi/Raw', it: 'Composizione: Semilavorati/Grezzi' },
  'Chemical Formula': { en: 'Chemical Formula', it: 'Formula Chimica' },
  'Molar weight': { en: 'Molar weight', it: 'Peso Molare' },
  'Specific weight': { en: 'Specific weight', it: 'Peso Specifico' },
  Type: { en: 'Type', it: 'Tipo' },
  'Physical State': { en: 'Physical State', it: 'Stato Fisico' },
  Main: { en: 'Main', it: 'Principale' },
  Properties: { en: 'Properties', it: 'Proprietà' },
  Business: { en: 'Business', it: 'Business' },
  Composition: { en: 'Composition', it: 'Composizione' },
  Created: { en: 'Created', it: 'Data creazione' },
  'Created at': { en: 'Created at', it: 'Data creazione' },
  Role: { en: 'Role', it: 'Ruolo' },
  Field: { en: 'Field', it: 'Campo' },
  Minimum: { en: 'Minimum', it: 'Minimo' },
  Maximum: { en: 'Maximum', it: 'Massimo' },
  Where: { en: 'Where', it: 'Dove' },
  Tension: { en: 'Tension', it: 'Tensione' },
  From: { en: 'From', it: 'Da' },
  To: { en: 'To', it: 'A' },
  'Optimization successfully completed': { en: 'Optimization successfully completed', it: 'Ottimizzazione completata' },
  'Target Oxides Composition ': { en: 'Target Oxides Composition ', it: 'Composizione obiettivo in ossidi' },
  'Result Formulation': { en: 'Result Formulation', it: 'Formulazione risultante' },
  Compare: { en: 'Compare', it: 'Confronto' },
  Aspect: { en: 'Aspect', it: 'Confronto' },

  'Delete Row': { en: 'Delete Row', it: 'Cancella riga' },
  'Add Row Before': { en: 'Add Row Before', it: 'Aggiungi riga prima' },
  'Add Row After': { en: 'Add Row After', it: 'Aggiungi riga dopo' },
  Consistency: { en: 'Consistency', it: 'Consistenza' },
  Solid: { en: 'Solid', it: 'Solido' },
  Liquid: { en: 'Liquid', it: 'Liquido' },
  Dummy: { en: 'Dummy', it: 'Fittizio' },
  MaterialDummy: { en: 'Dummy', it: 'Fittizio' },
  'Cannot edit cell on empty rows': {
    en: 'Cannot edit cell on empty rows',
    it: 'Impossibile modificare una tabella vuota',
  },
  'ctrl-to-open': {
    en: 'CTRL+Click to open the report in a new window',
    it: 'CTRL+Click per aprire il report in una nuova finestra',
  },
  'The problem is (trivially) infeasible because a singleton row in the equality constraints is inconsistent with the bounds.': {
    en: 'The problem is (trivially) infeasible because a singleton row in the equality constraints is inconsistent XXX bounds.',
    it: 'The problem is (trivially) infeasible because a singleton row in the equality constraints is inconsistent XXX bounds.',
  },
  'The solution was determined in presolve as there are no non-trivial constraints.': {
    en: 'The solution was determined in presolve as there are no non-trivial constraints.',
    it: 'The solution was determined in presolve as there are no non-trivial constraints.',
  },
  'Exception occurred in setting up the problem.': {
    en: 'Exception occurred in setting up the problem.',
    it: 'Eccezione durante la configurazione del problema.',
  },
  'The algorithm terminated successfully and determined that the problem is infeasible': {
    en: 'The algorithm terminated successfully and determined that the problem is infeasible',
    it: "L'algoritmo ha terminato con successo e ha determinato che il problema non è risolvibile.",
  },
  'Mean all compositions': { en: 'Mean all compositions', it: 'Media di tutte le composizioni' },
  True: { en: 'True', it: 'Sì' },
  False: { en: 'False', it: 'No' },
  And: { en: 'And', it: 'Tutti' },
  Or: { en: 'Or', it: 'Almeno uno' },
  'Last semester': { en: 'Last semester', it: 'Ultimo semestre' },
  'Last week': { en: 'Last week', it: 'Ultima settimana' },
  'Last month': { en: 'Last month', it: 'Ultimo mese' },
  'Last year': { en: 'Last year', it: 'Ultimo anno' },
  'Last quarter': { en: 'Last quarter', it: 'Ultimo trimestre' },
  All: { en: 'All', it: 'Tutti' },
  Key: { en: 'Key', it: 'Chiave' },
  Time: { en: 'Time', it: 'Tempo' },
  Message: { en: 'Message', it: 'Messaggio' },
  Priority: { en: 'Priority', it: 'Priorità' },
  'No data': { en: 'No data', it: 'Nessun dato' },
  Initial: { en: 'Initial', it: 'Iniziale' },
  Sintering: { en: 'Sintering', it: 'Sinterizzazione' },
  Softening: { en: 'Softening', it: 'Rammollimento' },
  Sphere: { en: 'Sphere', it: 'Sfera' },
  HalfSphere: { en: 'Half Sphere', it: 'Mezza Sfera' },
  'Half Sphere': { en: 'Half Sphere', it: 'Mezza Sfera' },
  Melting: { en: 'Melting', it: 'Fusione' },
  Shape: { en: 'Shape', it: 'Forma' },
  'Characteristic shapes': { en: 'Characteristic shapes ', it: 'Forme caratteristiche' },
  'Unique Identifier': { en: 'Unique Identifier ', it: 'Identificatore unico' },
  Successful: { en: 'Successful', it: 'Riuscito' },
  Aborted: { en: 'Aborted', it: 'Fallito' },
  'Is a PPM test?': { en: 'Is a PPM test?', it: 'È un test PPM?' },
  'Has duplicates': { en: 'Has duplicates', it: 'Presenta duplicati' },
  'Maximum temperature': { en: 'Maximum temperature', it: 'Temperatura massima' },
  'Maximum Heating Rate ': { en: 'Maximum Heating Rate ', it: 'Massima velocità di riscaldamento' },
  'Start temperature': { en: 'Start temperature', it: 'Temperatura di partenza' },
  Flavour: { en: 'Flavour', it: 'Tipo' },
  'Thermal cycle name ': { en: 'Thermal cycle name ', it: 'Nome del ciclo termico' },
  'Thermal cycle ': { en: 'Thermal cycle ', it: 'Ciclo termico' },
  Path: { en: 'Path', it: 'Percorso' },
  'Test id from name': { en: 'Test id from name', it: 'Id test dal nome' },
  'Generating host': { en: 'Generating host', it: 'Host di origine' },
  'Generation date': { en: 'Generation date', it: 'Data' },
  'Original file deleted': { en: 'Original file deleted', it: 'File originale cancellato' },
  'Backup file path ': { en: 'Backup file path ', it: 'Percorso di backup' },
  Billable: { en: 'Billable', it: 'Fatturabile' },
  'Billed Temperature': { en: 'Billed Temperature', it: 'Temperatura fatturata' },
  'Billed Rate': { en: 'Billed Rate', it: 'Velocità fatturata' },
  'Billed Cost': { en: 'Billed Cost', it: 'Costo fatturato' },
  'Billed Discount': { en: 'Billed Discount', it: 'Sconto fatturato' },
  'Billed Cumulative': { en: 'Billed Cumulative', it: 'Cumulativo fatturato' },
  'Billed Price': { en: 'Billed Price', it: 'Prezzo fatturato' },
  'End message': { en: 'End message', it: 'Messaggio di chiusura' },
  Messages: { en: 'Messages', it: 'Messaggi' },
  'Report generation': { en: 'Report generation', it: 'Generazione report' },
  'Report scanner version': { en: 'Report scanner version', it: 'Versione scansionatore di versione' },
  'Modifier document': { en: 'Modifier document', it: 'Documento modificante' },
  Version: { en: 'Version', it: 'Versione' },
  Referees: { en: 'Referees', it: 'Documenti collegati' },
  Style: { en: 'Style', it: 'Stile' },
  Attachments: { en: 'Attachments', it: 'Allegati' },
  'Customer Identifier': { en: 'Customer Identifier', it: 'Identificatore azienda' },
  'Customer ID': { en: 'Customer ID', it: 'Identificatore azienda' },
  'Version name': { en: 'Version name', it: 'Nome versione' },
  Bill: { en: 'Bill', it: 'Fatturazione' },
  Calcimeter: { en: 'Calcimeter', it: 'Calcimetro' },
  Cost: { en: 'Cost', it: 'Costo' },
  'Fired Cost': { en: 'Fired Cost', it: 'Costo al cotto' },
  'Molar Cost': { en: 'Molar Cost', it: 'Costo molare' },
  'Fired Molar Cost': { en: 'Fired Molar Cost', it: 'Costo molare al cotto' },
  Acceptance: { en: 'Acceptance', it: 'Accettazione' },
  Technological: { en: 'Technological', it: 'Tecnologico' },
  'Seger Fluxes RO': { en: 'Seger Fluxes RO', it: 'Flusso Seger RO' },
  'Seger Intermediates RO2': { en: 'Seger Intermediates RO2', it: 'Seger Intermedi RO2' },
  'Seger Formers R2O3': { en: 'Seger Formers R2O3', it: 'Seger Anteriori R2O3' },
  'Dilatation coefficients': { en: 'Dilatation coefficients', it: 'Coefficienti di dilatazione' },
  'Surface tension': { en: 'Surface tension', it: 'Tensione superficiale' },
  Viscosity: { en: 'Viscosity', it: 'Viscosità' },
  'Granulometric Analysis': { en: 'Granulometric Analysis', it: 'Granulometria' },
  'Specific surface': { en: 'Specific surface', it: 'Superficie specifica' },
  'Winkelmann-Shott Coefficient': { en: 'Winkelmann-Shott Coefficient', it: 'Coefficiente di Winkelmann-Shott' },
  'Refractive index (Dietzel)': { en: 'Refractive index (Dietzel)', it: 'Indice di rifrazione (Dietzel)' },
  'Dietzel Refraction': { en: 'Dietzel Refraction', it: 'Rifrazione di Dietzel' },
  'Tension Dietzel': { en: 'Tension Dietzel', it: 'Tensione di Dietzel' },
  'Tension Lyon': { en: 'Tension Lyon', it: 'Tensione di Lyon' },
  'Tension Appen': { en: 'Tension Appen', it: 'Tensione di Appen' },
  'Skipped constraints': { en: 'Skipped constraints', it: 'Vincoli ignorati' },
  Permissions: { en: 'Permissions', it: 'Permessi' },
  History: { en: 'History', it: 'Storico' },
  Read: { en: 'Read', it: 'Lettura' },
  Write: { en: 'Write', it: 'Scrittura' },
  Create: { en: 'Create', it: 'Crea' },
  Delete: { en: 'Delete', it: 'Elimina' },
  'Company Admin': { en: 'Company Admin', it: 'Amministratore' },
  Notifications: { en: 'Notifications', it: 'Notifiche' },
  Supplier: { en: 'Supplier', it: 'Fornitore' },
  'API Keys': { en: 'API Keys', it: 'Chiavi API' },
  'API Key': { en: 'API Key', it: 'Chiave' },
  'Key name': { en: 'Key name', it: 'Nome chiave' },
  'Manage user info': { en: 'Manage user info', it: 'Gestisci utente' },
  Notify: { en: 'Notify', it: 'Notifica' },
  Producer: { en: 'Producer', it: 'Produttore' },
  Deposit: { en: 'Deposit', it: 'Magazzino' },
  Origin: { en: 'Origin', it: 'Origine' },
  'Allowed material ranges': { en: 'Allowed material ranges', it: 'Materie prime o minerali' },
  'Custom constraints': { en: 'Custom constraints', it: 'Vincoli aggiuntivi' },
  Operation: { en: 'Operation', it: 'Operazione' },
  'Objective property': { en: 'Objective property', it: 'Proprietà ottimizzata' },
  'Minimize target property': { en: 'Minimize target property', it: 'Minimizza' },
  'Maximize target property': { en: 'Maximize target property', it: 'Massimizza' },
  'Pre-fired Weight Cost': { en: 'Pre-fired Weight Cost', it: 'Costo in crudo' },
  'Fired Weight Cost': { en: 'Fired Weight Cost', it: 'Costo in cotto' },
  'Pre-fired Molar cost': { en: 'Pre-fired Molar cost', it: 'Costo molare al crudo' },
  'Weight Loss': { en: 'Weight Loss', it: 'Perdita di peso' },
  'Enforce 100% total': { en: 'Enforce 100% total', it: 'Chiudi al 100%' },
  'Constrained components': { en: 'Constrained components', it: 'Componenti richiesti' },
  'Default tolerance': { en: 'Default tolerance', it: 'Tolleranza predefinita' },
  'Minimize tolerance': { en: 'Minimize tolerance', it: 'Minimizza tolleranza' },
  'Composition: Oxide': { en: 'Composition: Oxide', it: 'Composizione: Ossidi' },
  'Oxides Distance': { en: 'Oxides Distance', it: 'Distanza ossidi' },
  'Oxide from mix': { en: 'Oxide from mix', it: 'Ossido da mix' },
  'Oxides from Mixes Distance': { en: 'Oxides from Mixes Distance', it: 'Distanza ossidi dai mix' },
  'Contributions to Oxides': { en: 'Contributions to Oxides', it: 'Contributi agli ossidi' },
  'Contributions to Mix': { en: 'Contributions to Mix', it: 'Contributi ai mix' },
  Contributions: { en: 'Contributions', it: 'Contributi' },
  Coefficients: { en: 'Coefficients', it: 'Coefficienti' },
  'Coefficients (Oxide)': { en: 'Coefficients (Oxide)', it: 'Coefficienti (Ossido)' },
  Results: { en: 'Results', it: 'Risultati' },
  Result: { en: 'Result', it: 'Risultato' },
  Source: { en: 'Source', it: 'Sorgente' },
  'Local Cost': { en: 'Local Cost', it: 'Prezzo contestuale' },
  Success: { en: 'Success', it: 'Riuscito' },
  'Result Value': { en: 'Result Value', it: 'Valore risultante' },
  'Raw Cost': { en: 'Raw Cost', it: 'Costo grezzo' },
  'Final tolerance': { en: 'Final tolerance', it: 'Tolleranza finale' },
  Creator: { en: 'Creator', it: 'Autore' },
  Soluble: { en: 'Soluble', it: 'Solubile' },
  Flamable: { en: 'Flamable', it: 'Infiammabile' },
  Crystallized: { en: 'Crystallized', it: 'Cristallizzato' },
  Flashpoint: { en: 'Flashpoint', it: 'Punto di infiammabilità' },
  Corrosive: { en: 'Corrosive', it: 'Corrosivo' },
  'Weight loss on calcination': { en: 'Weight loss on calcination', it: 'Perdita di peso per calcinazione' },
  'Composition: Element': { en: 'Composition: Element', it: 'Composizione: Elementi' },
  Notes: { en: 'Notes', it: 'Note' },
  'Click to edit text': { en: 'Click to edit text', it: 'Click per modificare' },
  'Fluxes RO': { en: 'Fluxes RO', it: 'Flussi RO' },
  'Intermediates RO2': { en: 'Intermediates RO2', it: 'Intermedi RO2' },
  'Formers R2O3': { en: 'Formers R2O3', it: 'Anteriori R2O3' },
  'Override calculated value': { en: 'Override calculated value', it: 'Ignora valore calcolato' },
  Summary: { en: 'Summary', it: 'Sommario' },
  AnalysisHsm: { en: 'HSM analysis', it: 'Analisi HSM' },
  AnalysisDilatometer: { en: 'Dilatometer analysis', it: 'Analisi dilatometrica' },
  AnalysisGranulometry: { en: 'Granulometric analysis', it: 'Analisi granulometrica' },
  AnalysisOxides: { en: 'Oxides analysis', it: 'Analisi ossidi' },
  AnalysisMinerals: { en: 'Minerals analysis', it: 'Analisi mineralogica' },
  Flex: { en: 'Fleximeter', it: 'Flessimetro' },
  Flex3: { en: 'Triple fleximeter', it: 'Flessimetro triplo' },
  HSM: { en: 'HSM', it: 'HSM' },
  Horizontal: { en: 'Horizonal dilatometer', it: 'Dilatometro orizzontale' },
  Vertical: { en: 'Vertical dilatometer', it: 'Dilatometro verticale' },
  Kiln: { en: 'Kiln', it: 'Forno' },
  Quantity: { en: 'Quantity', it: 'Quantità' },
  Component: { en: 'Component', it: 'Componente' },
  'Min Quantity': { en: 'Min Quantity', it: 'Quantità minima' },
  'Max Quantity': { en: 'Max Quantity', it: 'Quantità massima' },
  'Min Molar': { en: 'Min Molar', it: 'Frazione molare minima' },
  'Max Molar': { en: 'Max Molar', it: 'Frazione molare massima' },
  'Molar fraction': { en: 'Molar fraction', it: 'Frazione molare' },
  'Fired quantity': { en: 'Fired quantity', it: 'Quantità cotta' },
  'Fired molar fraction': { en: 'Fired molar fraction', it: 'Frazione molare cotta' },
  'Location not found': { en: 'Location not found', it: 'Luogo non trovato' },
  'Initial sample dimension': { en: 'Initial sample dimension', it: 'Dimensione iniziale del campione' },
  'Max speed': { en: 'Max speed', it: 'Velocità massima' },
  'min speed': { en: 'min speed', it: 'Velocità minima' },
  'Max deformation': { en: 'Max deformation', it: 'Deformazione massima' },
  'Min deformation': { en: 'Min deformation', it: 'Deformazione minima' },
  Bloating: { en: 'Bloating', it: 'Rigonfiamento' },
  'Coefficient of thermal expansion': {
    en: 'Coefficient of thermal expansion',
    it: 'Coefficiente di espansione termica',
  },
  'Edit property': { en: 'Edit property', it: 'Modifica della proprietà' },
  'Pie plot': { en: 'Pie plot', it: 'Grafico a torta' },
  'plot for column': { en: 'plot for column', it: 'grafico della colonna' },
  'Missing document': { en: 'Missing document', it: 'Documento non trovato' },
  Number: { en: 'Number', it: 'Numero' },
  'Periodic group': { en: 'Periodic group', it: 'Gruppo della tavola periodica' },
  'Periodic row': { en: 'Periodic row', it: 'Riga della tavola periodica' },
  'Oxidation states': { en: 'Oxidation states', it: 'Stato di ossidazione' },
  State: { en: 'State', it: 'Stato' },
  Prevalence: { en: 'Prevalence', it: 'Prevalenza' },
  Radius: { en: 'Radius', it: 'Raggio' },
  Classification: { en: 'Classification', it: 'Classificazione' },
  Boiling: { en: 'Boiling', it: 'Ebollizione' },
  'Anionic radius': { en: 'Anionic radius', it: 'Raggio anionico' },
  Electronic: { en: 'Electronic', it: 'Elettronica' },
  'Cationic radius': { en: 'Cationic radius', it: 'Raggio cationico' },
  'Ionic radius': { en: 'Ionic radius', it: 'Raggio ionico' },
  'Metallic radius': { en: 'Metallic radius', it: 'Raggio metallico' },
  'Electronic structure': { en: 'Electronic structure', it: 'Struttura degli elettroni' },
  'Ground state': { en: 'Ground state', it: 'Stato di massa' },
  Modifier: { en: 'Modifier', it: 'Utente modificante' },
  'Parent Material': { en: 'Parent material', it: 'Materiale di origine' },
  Customer: { en: 'Customer', it: 'Azienda' },
  Factory: { en: 'Factory', it: 'Stabilimento' },
  'Parent Batch': { en: 'Parent batch', it: 'Lotto di origine' },
  'Parent Sample': { en: 'Parent sample', it: 'Campione di origine' },
  Preparation: { en: 'Preparation', it: 'Preparazione' },
  Position: { en: 'Position', it: 'Posizione' },
  'Index field and allow search': { en: 'Index field and allow search', it: 'Indicizza e consenti la ricerca' },
  'Minimum valid value': { en: 'Minimum valid value', it: 'Valore valido minimo' },
  'Maximum valid value': { en: 'Maximum valid value', it: 'Valore valido massimo' },
  'Source value': { en: 'Source value', it: 'Valore sorgente' },
  Quantità: { en: 'Quantità', it: 'Quantità' },
  Variables: { en: 'Variables', it: 'Variabili' },
  Equation: { en: 'Equation', it: 'Equazione' },
  Molar: { en: 'Molar', it: 'Moli' },
  Fired: { en: 'Fired', it: 'Cotto' },
  'Fired molar': { en: 'Fired molar', it: 'Moli al cotto' },
  'X column key': { en: 'X column key', it: 'Chiave della colonna X' },
  'Y column key': { en: 'Y column key', it: 'Chiave della colonna Y' },
  'Interpolate at X': { en: 'Interpolate at X', it: 'Interpolare su X' },
  Carrier: { en: 'Carrier', it: 'Trasportatore' },
  Address: { en: 'Address', it: 'Indirizzo' },
  'Parent Company': { en: 'Parent Company', it: 'Sede principale' },
  Description: { en: 'Description', it: 'Descrizione' },
  'Derive quantity or molar fraction': {
    en: 'Derive quantity or molar fraction',
    it: 'Derivare una quantità o una frazione molare',
  },
  'Molar fraction from quantities': { en: 'Molar fraction from quantities', it: 'Frazione molare da quantità' },
  'Quantity from molar fractions': { en: 'Quantity from molar fractions', it: 'Quantità da frazioni molari' },
  Disabled: { en: 'Disabled', it: 'Disabilitato' },
  'Derive pre-fired quantities from analytical': {
    en: 'Derive pre-fired quantities from analytical',
    it: 'Derivare le grandezze al crudo da quelle analitiche',
  },
  'Derive fired quantities from weight loss': {
    en: 'Derive fired quantities from weight loss',
    it: 'Derivare le quantità al cotto dalla perdita di peso',
  },
  'Composition: Compound': { en: 'Composition: Compound', it: 'Composizione: Composti' },
  None: { en: 'None', it: 'Nessuno' },
  Water: { en: 'Water', it: 'Acqua' },
  'Target documents type': { en: 'Target documents type', it: 'Tipo di documenti' },
  Documents: { en: 'Documents', it: 'Documenti' },
  'Duplicate of': { en: 'Duplicate of', it: 'Duplicati di' },
  'Related Tests': { en: 'Related tests', it: 'Prove correlate' },
  'Storage drive': { en: 'Storage drive', it: 'Disco di archiviazione' },
  'Backup time': { en: 'Backup time', it: 'Data di backup' },
  'Auth failure': { en: 'Auth failure', it: 'Errore di autenticazione' },
  cmp_Properties: { en: 'Properties (compare)', it: 'Properietà (confronto)' },
  cmp_main: { en: 'Main (compare)', it: 'Principale (confronto)' },
  cmp_electronic: { en: 'Electronic (compare)', it: 'Electronic (confronto)' },
  cmp_Fields: { en: 'Fields (compare)', it: 'Campi (confronto)' },
  cmp_Composition: { en: 'Composition (compare)', it: 'Composizione (confronto)' },
  cmp_business: { en: 'Business (compare)', it: 'Business (confronto)' },
  Expiry: { en: 'Expiry', it: 'Scadenza' },
  Functionalities: { en: 'Functionalities', it: 'Funzionalità' },
  Cumulative: { en: 'Cumulative', it: 'Crediti' },
  Rates: { en: 'Rates', it: 'Velocità di riscaldamento' },
  'Empty current': { en: 'Empty current', it: 'Svuota valore' },
  Restore: { en: 'Restore', it: 'Ripristina' },
  'Open test in MDF': { en: 'Open test in MDF', it: 'Aprire il test in MDF' },
  'Switch to value': { en: 'Switch to value', it: 'Valore grezzo' },
  'Switch to percent': { en: 'Switch to percent', it: 'Valore percentuale' },
  'View by temperature': { en: 'View by temperature', it: 'Base temperatura' },
  'View by time': { en: 'View by time', it: 'Base tempo' },

  'Use this manual procedure only if the instrument client cannot be directly connected to internet.': {
    en: 'Use this manual procedure only if the instrument client cannot be directly connected to internet.',
    it: 'Utilizzare questo manuale solo se il client dello strumento non può connettersi direttamente ad internet.',
  },
  'When Acquisition connects to an instrument with an invalid license, the license renewal dialog pops up.': {
    en: 'When Acquisition connects to an instrument with an invalid license, the license renewal dialog pops up.',
    it: 'Quando Acquisition si connette ad uno strumento con una licenza non valida, si apre il dialogo di rinnovo della licenza.',
  },
  'If the license renewal dialog is not visible, click on Server -> License to show it.': {
    en: 'If the license renewal dialog is not visible, click on Server -> License to show it.',
    it: 'Se il dialogo di rinnovo della licenza non è visibile, click su Server -> License per mostrarlo.',
  },
  'If the client is connected to internet, just click the Renew cloud license button. The license will be automatically renewed.': {
    en: 'If the client is connected to internet, just click the Renew cloud license button. The license will be automatically renewed.',
    it: 'Se il client è connesso ad internet, click sul bottone Renew cloud license. La licenza sarà rinnovata automaticamente.',
  },
  'client-not-connected': {
    en: 'If the client is not connected to the internet, click the Save instrument identifier button to export the unique identifier to <serial>.identifier file.',
    it: "Se il client non è connesso ad internet, click sul bottone Save instrument identifier per esportare l'identificativo unico al file <serial>.identifier.",
  },
  'Move the generated file (without renaming it) to an internet connected computer (eg: using an USB key).': {
    en: 'Move the generated file (without renaming it) to an internet connected computer (eg: using an USB key).',
    it: 'Spostare il file generato (senza rinominarlo) su un computer connesso ad internet (es: con una chiavetta USB).',
  },
  'Upload the <serial>.identifier file using the button below.': {
    en: 'Upload the <serial>.identifier file using the button below.',
    it: 'Caricare il file <serial>.identifier utilizzando il bottone qui sotto.',
  },
  'Download the resulting <serial>.license file and move it back to the client computer.': {
    en: 'Download the resulting <serial>.license file and move it back to the client computer.',
    it: 'Scaricare il file <serial>.license restituito e spostarlo al computer con il client.',
  },
  'In the license renewal dialog, click on Load a new license and wait for the result.': {
    en: 'In the license renewal dialog, click on Load a new license and wait for the result.',
    it: 'Nel dialogo di rinnovo della licenza, click su Load a new license ed aspettare il risultato.',
  },
  'Show/Hide result rows': { en: 'Show/Hide result rows', it: 'Mostra/Nascondi risultati' },
  Property_id: { en: 'Property_id', it: 'Id proprietà' },
  Property: { en: 'Property', it: 'Proprietà' },
  Visible: { en: 'Visible', it: 'Visibile' },
  Section: { en: 'Section', it: 'Sezione' },
  'Document comparison results': { en: 'Document comparison results', it: 'Documento di risultati del confronto' },
  'Maximum value must be greater than minimum value': {
    en: 'Maximum value must be greater than minimum value',
    it: 'Il valore massimo deve essere maggiore del valore minimo',
  },
  'Test target': { en: 'Test target', it: 'Report' },
  'Test targets': { en: 'Test targets', it: 'Report' },
  'Start Temp': { en: 'Start temp', it: 'Temperatura iniziale' },
  'End Temp': { en: 'End temp', it: 'Temperatura finale' },
  Method: { en: 'Method', it: 'Metodo' },
  Convolution: { en: 'Convolution', it: 'Convoluzione' },
  'Quartz density': { en: 'Quartz density', it: 'Densità del quarzo' },
  'Total density': { en: 'Total density', it: 'Densità complessiva' },
  'Show legend': { en: 'Show legend', it: 'Mostra legenda' },
  Percent: { en: 'Percent', it: 'Valori in percentuale' },
  'Time based (or temperature)': { en: 'Time based (or temperature)', it: 'Base tempo (o temperatura)' },
  Expansion: { en: 'Expansion', it: 'Espansione' },
  Model: { en: 'Model', it: 'Modello' },
  'Quartz Volume': { en: 'Quartz volume', it: 'Volume del quarzo' },
  'Quartz Weight': { en: 'Quartz weight', it: 'Peso del quarzo' },
  'Finest quartz fraction': { en: 'Finest quartz fraction', it: 'Frazione di quarzo più fine' },
  'Intermediate quartz fraction': { en: 'Intermediate quartz fraction', it: 'Frazione intermedia di quarzo' },
  'Rough quartz fracion': { en: 'Rough quartz fraction', it: 'Frazione di quarzo grezza' },
  'Post Transition Removal': { en: 'Post transition removal', it: 'Rimozione post-transizione' },
  'Delayed Smoothing of Quartz': { en: 'Delayed smoothing of quartz', it: 'Livellamento posticipato del quarzo' },
  'Linear Combination of 2 Granulometries': {
    en: 'Linear combination of 2 granulometries',
    it: 'Combinazione lineare di 2 granulometrie',
  },
  'Linear Combination of 3 Granulometries': {
    en: 'Linear combination of 3 granulometries',
    it: 'Combinazione lineare di 3 granulometrie',
  },
  Gaussian: { en: 'Gaussian', it: 'Gaussiana' },
  Exponential: { en: 'Exponential', it: 'Esponenziale' },
  'Fraser-Suzuki': { en: 'Fraser-Suzuki', it: 'Fraser-Suzuki' },
  'Compare documents': { en: 'Compare documents', it: 'Confronto di documenti' },
  'Show markpoints': { en: 'Show markpoints', it: 'Mostra markpoint' },
  'Hide markpoints': { en: 'Hide markpoints', it: 'Nascondi markpoint' },
  'Base layer expansion': { en: 'Base layer expansion', it: 'Base layer expansion' },
  'Top layer expansion': { en: 'Top layer expansion', it: 'Top layer expansion' },
  Fleximetry: { en: 'Fleximetry', it: 'Flessimetria' },
  'Coupling temperature': { en: 'Coupling temperature', it: 'Temperatura di accoppiamento' },
  'Room T stress': { en: 'Room T stress', it: 'Room T stress' },
  'Pyroplasticity test': { en: 'Pyroplasticity test', it: 'Prova di piroplasticità' },
  'Sintering test': { en: 'Sintering test', it: 'Prova di sinterizzazione' },
  'Remote support request': { en: 'Remote support request', it: 'Richiesta di supporto remoto' },
  'Click to edit unit': { en: 'Click to edit unit', it: "Click per modificare l'unità" },
  'Type to search': { en: 'Type to search', it: 'Digita per cercare' },
  'Remove attachment': { en: 'Remove attachment', it: 'Rimuovi allegato' },
  'Share attachment': { en: 'Share attachment', it: 'Condividi allegato' },
  'Download attachment': { en: 'Download attachment', it: 'Scarica allegato' },
  'Enable markpoints to add': { en: 'Enable markpoints to add', it: 'Abilita i markpoint per crearne uno' },
  'Cannot edit Meta markpoints': { en: 'Cannot edit Meta markpoints', it: 'Impossibile modificare i markpoint di metadati' },
  'Cannot add multiple markpoints with the same label': {
    en: 'Cannot add multiple markpoints with the same label',
    it: 'Impossibile aggiungere più markpoint con la stessa etichetta',
  },
  'Create new markpoint': { en: 'Create new markpoint', it: 'Crea nuovo markpoint' },
  'Edit markpoint': { en: 'Edit markpoint', it: 'Modifica markpoint' },
  Label: { en: 'Label', it: 'Etichetta' },
  'One plot per test': { en: 'One plot per test', it: 'Un grafico per report' },
};

export function _(str, current_translations = translations, destination_language = globalThis.get_cookie('lang') || 'en') {
  try {
    const translations_from_english = Object.keys(current_translations);
    const str_translation_key = translations_from_english.find((key) => key.toLowerCase().trim() === str.toLowerCase().trim());

    return new object_with_default(current_translations[str_translation_key] || {}, str)[destination_language].trim();
  } catch (e) {
    return str;
  }
}

globalThis._ = _;
