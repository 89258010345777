var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id='"
    + alias4(((helper = (helper = lookupProperty(helpers,"doc_menu_id") || (depth0 != null ? lookupProperty(depth0,"doc_menu_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"doc_menu_id","hash":{},"data":data,"loc":{"start":{"line":1,"column":9},"end":{"line":1,"column":24}}}) : helper)))
    + "' class='float-right' x-data='"
    + alias4(((helper = (helper = lookupProperty(helpers,"x_data") || (depth0 != null ? lookupProperty(depth0,"x_data") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"x_data","hash":{},"data":data,"loc":{"start":{"line":1,"column":54},"end":{"line":1,"column":64}}}) : helper)))
    + "'>\n  <i\n    id='scan_file_icon'\n    :title='_(\"Scan file\")'\n    :class='get_document_menu_icons_classes()'\n    class='fa-arrows-rotate'\n    x-show='user_data.info[\"customer_id\"] === 2'\n    @click='current_document_conf.scan_file()'\n  ></i>\n  <i\n    id='share_report_icon'\n    :title='_(\"Share\")'\n    :class='get_document_menu_icons_classes()'\n    class='fa-share-nodes'\n    x-show='user_data.info[\"customer_id\"] === 2'\n    @click='current_document_conf.share_document(\""
    + alias4(((helper = (helper = lookupProperty(helpers,"report_uid") || (depth0 != null ? lookupProperty(depth0,"report_uid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"report_uid","hash":{},"data":data,"loc":{"start":{"line":16,"column":50},"end":{"line":16,"column":64}}}) : helper)))
    + "\")'\n  ></i>\n  <i\n    id='copy_report_to_partition_icon'\n    :title='_(\"Copy to partition\")'\n    :class='get_document_menu_icons_classes()'\n    class='fa-solid fa-share-from-square'\n    x-show='\""
    + alias4(((helper = (helper = lookupProperty(helpers,"current_partition") || (depth0 != null ? lookupProperty(depth0,"current_partition") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"current_partition","hash":{},"data":data,"loc":{"start":{"line":23,"column":13},"end":{"line":23,"column":34}}}) : helper)))
    + "\" !== \"0R\"'\n    @click='current_document_conf.copy_report_to_partition(\""
    + alias4(((helper = (helper = lookupProperty(helpers,"report_uid") || (depth0 != null ? lookupProperty(depth0,"report_uid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"report_uid","hash":{},"data":data,"loc":{"start":{"line":24,"column":60},"end":{"line":24,"column":74}}}) : helper)))
    + "\")'\n  ></i>\n  <a\n    :href=\"new_document_url('model_plot')\"\n    rel='noreferrer noopener'\n    target='_blank'\n    @click='current_document_conf.push_report_state_to_local_storage(\"Plot\")'\n  >\n    <i id='btn_overlay' :title='_(\"Create model overlay\")' :class='get_document_menu_icons_classes()' class='fa-solid fa-chart-line'>\n    </i>\n  </a>\n  <a\n    :href=\"new_document_url('model_quartz')\"\n    rel='noreferrer noopener'\n    target='_blank'\n    @click='current_document_conf.push_report_state_to_local_storage(\"Quartz\")'\n  >\n    <i id='btn_quartz' :title='_(\"Create model quartz\")' :class='get_document_menu_icons_classes()' class='fa-solid fa-gem' x-show='show_quartz_icon'>\n    </i>\n  </a>\n  <i\n    id='save_report_edits_icon'\n    :title='_(\"Save edits\")'\n    :class='get_document_menu_icons_classes()'\n    class='fa-floppy-disk text-elsred'\n    x-show='save_edits'\n    @click='current_document_conf.confirm_edit_dialog()'\n  ></i>\n  <i\n    id='cancel_report_edits_icon'\n    :title='_(\"Cancel edits\")'\n    :class='get_document_menu_icons_classes()'\n    class='fa-rotate-left text-elsred'\n    x-show='cancel_edits'\n    @click='current_document_conf.confirm_revert_dialog()'\n  ></i>\n  <i\n    id='export_report_icon'\n    :title='_(\"Export report\")'\n    :class='get_document_menu_icons_classes()'\n    class='fa-upload'\n    @click='current_document_conf.export_document()'\n  ></i>\n  <i\n    id='btn_compare'\n    :title='_(\"Compare\")'\n    :class='get_document_menu_icons_classes()'\n    class='fa-solid fa-scale-balanced'\n    @click='compare_documents(current_document_conf.uid, current_document_conf.class_name, current_document_conf.currents, \"report\")'\n  >\n  </i>\n  <i\n    id='btn_download'\n    :title='_(\"Download original file\")'\n    :class='get_document_menu_icons_classes()'\n    class='fa-solid fa-file-arrow-down'\n    @click='create_link_and_download(\""
    + alias4(((helper = (helper = lookupProperty(helpers,"report_uid") || (depth0 != null ? lookupProperty(depth0,"report_uid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"report_uid","hash":{},"data":data,"loc":{"start":{"line":80,"column":38},"end":{"line":80,"column":52}}}) : helper)))
    + "\")'\n  >\n  </i>\n  <i\n    id='btn_duplicates'\n    :title='_(\"List duplicates\")'\n    :class='get_document_menu_icons_classes()'\n    class='fa-solid fa-d'\n    @click='search_from_report_with_parameter(\"duplicateOf\", \""
    + alias4(((helper = (helper = lookupProperty(helpers,"report_id") || (depth0 != null ? lookupProperty(depth0,"report_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"report_id","hash":{},"data":data,"loc":{"start":{"line":88,"column":62},"end":{"line":88,"column":75}}}) : helper)))
    + "\")'\n  >\n  </i>\n  <i\n    id='btn_related'\n    :title='_(\"Find related tests\")'\n    :class='get_document_menu_icons_classes()'\n    class='fa-solid fa-link'\n    @click='search_from_report_with_parameter(\"related\", \""
    + alias4(((helper = (helper = lookupProperty(helpers,"report_id") || (depth0 != null ? lookupProperty(depth0,"report_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"report_id","hash":{},"data":data,"loc":{"start":{"line":96,"column":58},"end":{"line":96,"column":71}}}) : helper)))
    + "\")'\n  >\n  </i>\n  <i\n    id='btn_thermalcycle'\n    :title='_(\"Find tests with same thermal cycle\")'\n    :class='get_document_menu_icons_classes()'\n    class='fa-solid fa-temperature-arrow-up'\n    @click='search_from_report_with_parameter(\"curveHash\", \""
    + alias4(((helper = (helper = lookupProperty(helpers,"report_curveHash") || (depth0 != null ? lookupProperty(depth0,"report_curveHash") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"report_curveHash","hash":{},"data":data,"loc":{"start":{"line":104,"column":60},"end":{"line":104,"column":80}}}) : helper)))
    + "\")'\n  >\n  </i>\n  <p id='link_download' class='clear-both pt-2 pb-2 hidden'></p>\n</div>";
},"useData":true});