import { rpc } from '../common/crystal_api/rpc';
import { show_ephemeral_toast } from '../common/toast';
import { empty_element } from '../common/ui';
import { show_dict_properties_pushing_state } from './show_dict_properties';
import { Conf } from './conf';
import { Tabulator } from 'tabulator-tables';
import { is_genome_selected } from '../common/hash';

globalThis.comparing_ids = [];
let compare_target = '';

globalThis.get_fast_compare_delta = () => {
  const delta = document.getElementById('fast_compare_delta') ? JSON.parse(document.getElementById('fast_compare_delta').value) : true;
  return delta;
};

function start_fast_compare(initial_doc, target) {
  const comparing_doc = { id: initial_doc['_id'], name: initial_doc['name'], version: initial_doc['version'] };
  globalThis.comparing_ids.push(comparing_doc);
  compare_target = target;
  rpc.compare({ docids: [initial_doc['_id']], delta: true }).then((resp) => compare_init(resp, true));
}

function compare_init(resp, default_delta = true) {
  resp.currents.style = ['placeholder#Compare#0', 'Compare'];
  resp.currents.name = 'Comparison';
  const comparison_container = document.getElementById('document_container');
  empty_element(comparison_container);
  const fast_compare_conf = new FastCompare(resp['currents'], resp['styles'], default_delta, compare_target);
  delete globalThis.current_document_conf;
  globalThis.current_document_conf = fast_compare_conf;
  fast_compare_conf.view(comparison_container);
}

function add_doc_to_fast_compare(doc) {
  if (globalThis.comparing_ids.some((ids) => ids['id'] === doc['_id'])) {
    show_ephemeral_toast(_('comparison-duplicate'), 'Error');
    return;
  }
  const comparing_doc = { id: doc['_id'], name: doc['name'], version: doc['version'] };
  globalThis.comparing_ids.push(comparing_doc);
  update_fast_compare();
}

globalThis.update_fast_compare = () => {
  const delta = get_fast_compare_delta();
  const ids = globalThis.comparing_ids.map((doc) => doc['id']);
  rpc.compare({ docids: ids, delta: delta }).then((resp) => compare_init(resp, delta));
};

globalThis.remove_from_fast_compare = (el) => {
  const removing_id = el['options'][el.selectedIndex].value;
  if (globalThis.comparing_ids.length === 2) {
    show_ephemeral_toast(_('2-docs-needed'), 'Error');
    document.getElementById('fast_compare_default').selected = true;
    return;
  }
  globalThis.comparing_ids = globalThis.comparing_ids.filter((doc) => doc['id'] !== removing_id);
  document.getElementById('fast_compare_selector').removeChild(el['options'][el.selectedIndex]);
  update_fast_compare();
};

let document_before_compare = '';

function show_genome_or_report_compare_table(database, search_doc) {
  if (database === 'genome') {
    globalThis.current_search_event = 'compare-genome-search';
    show_materials_table_ignoring_query_hash(search_doc, false, 'compare');
  } else if (database === 'report') {
    globalThis.current_search_event = 'compare-reports-search';
    dispatch_event('compare-reports-search');
  }
}

globalThis.compare_documents = (uid, class_name, currents, db) => {
  console.log('compare started');
  globalThis.comparing = true;
  const search_doc = {};
  search_doc.classes = [class_name];
  if (uid.includes('ver#')) {
    let current_id = uid.split('ver#')[1];
    current_id = current_id.split('#' + currents.version)[0];
    search_doc.id_for_versions = current_id;
  }
  globalThis.comparing_classes = search_doc['classes'];
  show_genome_or_report_compare_table(db, search_doc);
  dispatch_event('show-search-results');
  globalThis.history.pushState('Compare', 'Compare', globalThis.location);
  const compare_doc = Object.fromEntries(Object.entries(currents).filter(([key]) => ['name', '_id', 'version'].includes(key)));
  start_fast_compare(compare_doc, currents['class_name']);
  document_before_compare = uid;
};

class FastCompare extends Conf {
  constructor(currents, styles, default_delta, target) {
    super(currents, styles);
    this.is_fast_compare = true;
    this.default_delta = default_delta;
    this.compare_target = target;
  }

  get_template_file() {
    return 'fast_compare_view.handlebars';
  }

  can_initiate_a_formulation() {
    return false;
  }

  can_initiate_a_semi() {
    return false;
  }

  get_document_desc() {
    this._check_section();
    let default_section_name = this.sorted_sections.includes('cmp_main') ? 'cmp_main' : this.sorted_sections.filter((s) => s.startsWith('cmp_'))[0];
    const doc_section_list = this.sorted_sections.map((sec_name) => `${sec_name}: ${sec_name === default_section_name}`);
    const doc_active_section = `{${doc_section_list.join(',')}}`;

    const desc = {
      doc_uid: this.uid,
      show_results: globalThis.comparing_ids.length > 1,
      object_id: this.objid,
      x_data: doc_active_section,
    };
    return desc;
  }

  end_compare() {
    const doc_id = document_before_compare;
    globalThis.comparing = false;
    globalThis.comparing_classes = [];
    globalThis.comparing_ids = [];
    const target = document.getElementById('document_container');
    empty_element(target);
    if (is_genome_selected()) {
      rpc.doc({ uid: doc_id }).then((response) => show_dict_properties_pushing_state(target, response));
      globalThis.show_materials_table({}, false, false);
    } else {
      show_report_updating_history(doc_id);
      dispatch_event('report-search-results');
    }
  }

  show_fast_compare_table() {
    const fast_compare_table_options = {
      layout: 'fitColumns',
      columns: [{ title: _('Property') }, { title: globalThis.comparing_ids[0]['name'] }],
    };
    new Tabulator('#fast_compare_empty_table', fast_compare_table_options);
  }

  populate_fast_compare_selector(parent) {
    globalThis.comparing_ids.forEach((doc) => {
      const option = document.createElement('option');
      option.value = doc['id'];
      option.innerHTML = doc['version'] ? `${doc['name']} v. ${doc['version']}` : doc['name'];
      parent.appendChild(option);
    });
  }

  push_to_local_storage(conf_type) {
    const comparing_documents = [];
    globalThis.comparing_ids.forEach((doc) => {
      comparing_documents.push([[doc['id'], doc['name']]]);
    });
    const fast_compare_input = JSON.stringify({
      delta: get_fast_compare_delta(),
      target_type: this.compare_target,
      docs: comparing_documents,
    });
    set_cookie(`new_conf_initial_state_${conf_type}`, fast_compare_input);
  }
}

export { add_doc_to_fast_compare };
