import { capitalize_string } from '../common/utils';
import { rpc } from '../common/crystal_api/rpc';
import { empty_element, remove_element, show_element } from '../common/ui';
import { show_dict_properties, show_dict_properties_pushing_state } from './show_dict_properties';
import { get_hash, get_selected_db, get_selected_db_id } from '../common/hash';
import { init_genome_navbar, init_ligo_navbar } from '../common/navbar';
import { save_toast_message_in_cookie, show_ephemeral_toast } from '../common/toast';

globalThis.change_user_database = (db_id, event) => {
  if (event.ctrlKey) {
    change_user_database_in_new_page(db_id);
    return;
  }
  reset_url(db_id);
  initialize_db_list();
  location.reload();
  return;
};

function change_user_database_in_new_page(db_id) {
  const new_url = `#${db_id}`;
  window.open(new_url);
  return;
}

function reset_url(db_id) {
  const hash = `#${db_id}` || '';
  const new_url = `${location.href.split('#')[0].split('?')[0]}${hash}`;
  globalThis.location.href = new_url;
  reset_queryHash();
}

function initialize_db_list() {
  let selected_db = get_selected_db(location.hash, globalThis.user_data.databases);
  empty_element('databases-li');
  location.hash = get_hash(globalThis.user_data.databases);
  if (globalThis.user_data.databases) {
    const db_list = [];
    for (const [dbname, db_key] of Object.entries(globalThis.user_data.databases)) {
      db_list.push({ id: `menu_db_${dbname}`, text: dbname, value: db_key, selected: dbname === selected_db });
    }
    const databases_template = require('../view/navbar/databases_menu.handlebars');
    const databases_html = databases_template({ databases: JSON.stringify({ databases: db_list }) });
    document.getElementById('databases-li').insertAdjacentHTML('beforeend', databases_html);
  }
}

function show_document_by_uid(uid) {
  if (!uid) return new Promise((resolve) => resolve());

  set_query_param('uid', uid);
  return rpc
    .doc({ uid: uid }, false, false)
    .then(async (response) => show_dict_properties(await getElementByIdWithRetry('document_container'), response));
}

function cancel_doc_creation() {
  save_toast_message_in_cookie(_('Document creation deleted'), 'Info');
  location.href = get_selected_db_id();
}

async function new_document_from_navbar(name, mtype, resp) {
  console.log('new_doc');
  const dict = {};
  for (const [key, value] of Object.entries(resp)) {
    if (value['factory_default'] !== undefined) {
      dict[key] = value['factory_default'];
    } else {
      dict[key] = value;
    }
  }
  dict['name'] = name;
  dict['mtype'] = mtype;
  const target = await globalThis.getElementByIdWithRetry('document_container');
  return show_dict_properties(target, dict);
}

function new_doc_callback(name, mtype, parent = false, currents, styles = {}) {
  const values = Object.entries(currents).filter(([key, value]) => ![null, undefined].includes(value));
  const dict = values.reduce((acc, [key, value]) => {
    if (value['factory_default'] !== undefined) {
      acc[key] = value['factory_default'];
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});

  dict['name'] = name;
  dict['mtype'] = mtype;
  if (parent && mtype === 'Batch') {
    dict['parent'] = [parent['_id'], parent['name']];
  }
  const target = document.getElementById('document_container');
  show_dict_properties_pushing_state(target, dict, styles);
}

const material_types = ['oxide', 'compound', 'mix', 'raw', 'semi', 'product', 'element'];

globalThis.new_document_url = (type) => {
  let queryHash = get_parameter('queryHash') ? `queryHash=${get_parameter('queryHash')}` : '';
  let class_type = type;
  let pre = material_types.includes(class_type) ? 'def:Style#Material' : 'def:Style#';
  if (class_type.startsWith('model_')) {
    pre = 'def:Style#Model';
    class_type = class_type.split('model_')[1];
  }
  class_type = `docId=${pre}${capitalize_string(class_type)}#0`;
  return `${get_selected_db_id()}?${queryHash}&${class_type}`;
};

function confirm_doc_creation(type, parent = false) {
  console.log('doc creation confirm');
  let name = '';
  if (parent['name'] && (parent['version'] || parent['version'] === 0)) {
    name = parent['name'] + ', version ' + parent['version'];
  }
  let class_type = type;
  const mtype = type;
  let pre = 'def:Style#';
  if (material_types.includes(class_type)) {
    pre += 'Material';
  }
  class_type = `${pre}${capitalize_string(class_type)}#0`;
  return rpc
    .doc({
      uid: class_type,
    })
    .then((response) => new_doc_callback(name, mtype, parent, response));
}

function remove_document_callback(resp) {
  console.log('remove doc confirmed');
  show_ephemeral_toast(`${_('Deleted document')}: <b>${resp.deleted[0].split('#')[1]}</b>`, 'Info');
  show_element('search_div');
  document.title = 'Ceramics Genome';
  const path = `${get_selected_db_id()}`;
  path.endsWith('R') ? init_ligo_navbar() : init_genome_navbar();
  refresh_table();
  empty_element('document_container');
  globalThis.history.pushState(path, 'Show', path);
}

function remove_document_confirmed(el, uid) {
  rpc.delete({ uid: uid }).then(remove_document_callback);
}

function clone_confirm(currents, styles, modal_id) {
  const old_name = currents['name'];
  const original_name = currents['name'];
  const doc_name = document.getElementById('clone_input').value;
  if (currents['name'] !== doc_name) {
    currents['name'] = doc_name;
  }
  console.log('clone_doc data');
  if (!currents['name']) {
    document.getElementById('clone_error_msg').innerHTML = 'ERROR! Insert a name';
    currents['name'] = old_name;
    return;
  } else if (currents['name'] == original_name) {
    document.getElementById('clone_input').setCustomValidity('Invalid');
    document.getElementById('clone_error_msg').innerHTML = 'ERROR! New name must be different from document name';
    return;
  } else {
    document.getElementById(modal_id).remove();
  }
  currents['_id'] = currents['style'][0];
  new_doc_callback(doc_name, currents['mtype'], false, currents, styles);
}

function close_view_table_overlay() {
  remove_element('table_view_overlay');
}

globalThis.cancel_doc_creation = cancel_doc_creation;
globalThis.close_view_table_overlay = close_view_table_overlay;
globalThis.confirm_doc_creation = confirm_doc_creation;

export { initialize_db_list, show_document_by_uid, confirm_doc_creation, clone_confirm, remove_document_confirmed, new_document_from_navbar };
