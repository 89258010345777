import { Conf } from './conf';
import { empty_element, hide_element, show_element } from '../common/ui';

export class SplitConf extends Conf {
  constructor(currents, styles) {
    super(currents, styles);
    this.is_splitted_view = true;
    this.is_split_conf = true;
  }

  show_document_section(section_name, sections) {
    let left_sections = ['main', 'Admin'];
    let right_sections = sections.filter((name) => !['main', 'Admin'].includes(name));
    if (!this.is_splitted_view) {
      left_sections = [];
      right_sections.splice(0, 0, 'main');
      right_sections.push('Admin');
    }
    let toggle = ``;
    if (left_sections.includes(section_name)) {
      for (let d of left_sections) {
        if (section_name !== d) {
          toggle += `${d} = false;`;
        } else {
          toggle += `${d} = true;`;
        }
      }
      return toggle;
    }
    for (let d of right_sections) {
      if (section_name !== d) {
        toggle += `${d} = false;`;
      } else {
        toggle += `${d} = true;`;
      }
    }
    return toggle;
  }

  empty_split_conf() {
    empty_element('document_container');
  }

  minimize_document() {
    this.is_splitted_view = false;
    this.refresh_view();
  }

  maximize_document() {
    this.is_splitted_view = true;
    this.refresh_view();
  }

  refresh_view() {
    this.empty_split_conf();
    super.view();
    this.show_action_buttons();
  }

  get_document_desc() {
    const default_left = 'main';
    let default_right = 'Formulation';
    const result_default_section = this.sorted_sections.includes('cmp_main')
      ? 'cmp_main'
      : this.sorted_sections.filter((s) => s.startsWith('cmp_'))[0];
    if (this.class_name === 'Compare') default_right = result_default_section;
    if (this.class_name.startsWith('Model')) default_right = 'Results';
    if (!this.is_splitted_view) default_right = default_left;
    let div_data_string = '{';
    const data_array = this.sorted_sections.map((sec_name) => `${sec_name}: ${sec_name === default_left || sec_name === default_right}`);
    div_data_string += data_array.join(',');
    div_data_string += '}';
    let desc = super.get_document_desc();
    desc['x_data'] = div_data_string;
    return desc;
  }

  get_sections_tab_list() {
    let left_tabs_list = [];
    let right_tabs_list = [];
    for (let j = 0; j < this.sorted_sections.length; j++) {
      const sec = this.sorted_sections[j];
      const s = this.styles[sec];
      const hidden_section = s && s['attr'].includes('Hidden');
      if (!hidden_section && ['main', 'Admin'].includes(sec) && this.is_splitted_view) {
        left_tabs_list.push(this.get_sections_tab(j, this.sorted_sections));
      } else {
        right_tabs_list.push(this.get_sections_tab(j, this.sorted_sections));
      }
    }
    if (!this.is_splitted_view) {
      return JSON.stringify({
        sections_tab_list: right_tabs_list,
      });
    }
    return JSON.stringify({
      left_sections_tab_list: left_tabs_list,
      right_sections_tab_list: right_tabs_list,
    });
  }

  get_sections_content_list(ignore = []) {
    const left_section_desc = [];
    const right_section_desc = [];
    for (let j = 0; j < this.sorted_sections.length; j++) {
      const h = this.sections[this.sorted_sections[j]];
      const current_section_options = [];
      for (let i = 0; i < h.length; i++) {
        const current_option = this.options[h[i]];
        if (current_option.type !== 'Section' && !ignore.includes(current_option.handle)) {
          const option_uuid = current_option.create_template_option(this.class_name.startsWith('Report'));
          current_section_options.push(option_uuid);
        }
      }
      if (['main', 'Admin'].includes(this.sorted_sections[j]) && this.is_splitted_view) {
        left_section_desc.push([`section_${this.sorted_sections[j]}`, this.sorted_sections[j], current_section_options]);
      } else {
        right_section_desc.push([`section_${this.sorted_sections[j]}`, this.sorted_sections[j], current_section_options]);
      }
    }
    if (!this.is_splitted_view) {
      return JSON.stringify({
        section_contents_list: right_section_desc,
      });
    }
    return JSON.stringify({
      left_section_contents_list: left_section_desc,
      right_section_contents_list: right_section_desc,
    });
  }

  get_template_file() {
    if (!this.is_splitted_view) {
      return 'document_view.handlebars';
    }
    return 'document_splitted_view.handlebars';
  }

  split_view_full_edit() {
    this.full_edit();
    if (!this.executed) {
      hide_element(document.getElementById('split_conf_right_div'));
      hide_element(document.getElementById('rTab'));
      hide_element(document.getElementById('rTabContent'));
    }
  }

  full_edit_or_show_normal() {
    if (this.is_new()) {
      this.split_view_full_edit();
    } else {
      this.template_split_view();
      if (this.class_name === 'Formulation') {
        this.show_formulation_toast();
      }
    }
  }

  template_split_view() {
    show_element(document.getElementById('split_conf_left_div'));
    show_element(document.getElementById('split_conf_right_div'));
    show_element(document.getElementById('rTab'));
    show_element(document.getElementById('rTabContent'));
  }
}
