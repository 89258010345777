var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id='pie_plot_overlay' class='h-full w-full top-0 left-0 z-30 bg-neutral-600 opacity-95 fixed text-center'>\n  <button\n    @click='remove_element(\"pie_plot_overlay\")'\n    class='p-4 right-10 text-6xl text-gray-400 hover:text-white absolute fa-solid fa-xmark'\n    type='button'\n  >\n  </button>\n  <h1\n    class='mt-8 font-bold text-black'\n    x-text=\"'"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":10,"column":13},"end":{"line":10,"column":22}}}) : helper)))
    + "'\"\n  ></h1>\n  <div id='plot_container' class='p-6 w-full h-5/6'></div>\n</div>";
},"useData":true});