var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id='static_shapes_dialog' class='relative z-10' role='dialog'>\n  <div\n    x-transition:enter='ease-out duration-1000'\n    x-transition:enter-start='opacity-0'\n    x-transition:enter-end='opacity-100'\n    x-transition:leave='ease-in duration-200'\n    x-transition:leave-start='opacity-100'\n    x-transition:leave-end='opacity-0'\n    class='inset-0 bg-gray-500 bg-opacity-75 transition-opacity fixed'\n  >\n  </div>\n\n  <div class='fixed inset-0 z-10 overflow-y-auto'>\n    <div class='flex min-h-full justify-center text-center items-center p-0'>\n      <div\n        x-transition:enter='ease-out duration-300'\n        x-transition:enter-start='opacity-0 translate-y-4 translate-y-0 scale-95'\n        x-transition:enter-end='opacity-100 translate-y-0 scale-100'\n        x-transition:leave='ease-in duration-200'\n        x-transition:leave-start='opacity-100 translate-y-0 scale-100'\n        x-transition:leave-end='opacity-0 translate-y-4 translate-y-0 scale-95'\n        class='transform overflow-hidden rounded-sm bg-white text-left shadow-xl transition-all my-8 w-full max-w-lg relative'\n      >\n        <div class='bg-gray-100 py-3 flex px-6'>\n          <h3 data-cy='static-shapes-dialog-title' class='text-lg font-medium leading-6 !text-elsred' x-text='_(\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":25,"column":113},"end":{"line":25,"column":122}}}) : helper)))
    + "\")'>\n          </h3>\n        </div>\n        <div class='bg-white bg-opacity-30'>\n          <div class='items-start'>\n            <div class='p-4'>\n              <p class='border-2 border-gray-400' x-effect=\"$dispatch('static_shapes_dialog_ready',{'dialog':$el})\"></p>\n            </div>\n          </div>\n        </div>\n        <div class='px-4 py-3 space-x-2 float-right'>\n          <button\n            data-cy='unset-shape'\n            type='button'\n            :class='get_filled_button_classes()'\n            @click='() => {unset_shape_image("
    + alias4(((helper = (helper = lookupProperty(helpers,"sample_idx") || (depth0 != null ? lookupProperty(depth0,"sample_idx") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sample_idx","hash":{},"data":data,"loc":{"start":{"line":40,"column":45},"end":{"line":40,"column":59}}}) : helper)))
    + ", \""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":40,"column":62},"end":{"line":40,"column":71}}}) : helper)))
    + "\");remove_element(\"static_shapes_dialog\")}'\n            x-text='_(\"Unset\")'\n            x-show='"
    + alias4(((helper = (helper = lookupProperty(helpers,"show_unset_button") || (depth0 != null ? lookupProperty(depth0,"show_unset_button") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"show_unset_button","hash":{},"data":data,"loc":{"start":{"line":42,"column":20},"end":{"line":42,"column":41}}}) : helper)))
    + "'\n          >\n          </button>\n          <button type='button' :class='get_outline_button_classes()' @click='remove_element(\"static_shapes_dialog\")' x-text='_(\"Close\")'>\n          </button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});