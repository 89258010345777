import { show_content } from './contents_visibility';
import { rpc } from './crystal_api/rpc';
import { init_user_navbar } from './navbar';
import { capitalize_string } from './utils';

function customers_page_content() {
  const customers_page_template = require(`../view/customers_page_content.handlebars`);
  const customers_page_html = customers_page_template();
  document.getElementById('admin_page_content').insertAdjacentHTML('beforeend', customers_page_html);
  return true;
}

function render_customers(customers) {
  for (const [name, cid] of Object.entries(customers)) {
    const customer_element = require(`../view/customers_page_elements.handlebars`);
    const desc = {
      cid: cid,
      name: name,
      genome_link: `/#${cid}/0G`,
      report_link: `/#${cid}/0R`,
    };
    const customers_element_html = customer_element(desc);
    document.getElementById('customers-list').insertAdjacentHTML('beforeend', customers_element_html);
  }
}

function show_customers() {
  const admin_page = document.getElementById('admin_page_content');
  empty_element(admin_page);
  customers_page_content();
  rpc.list_customers().then((resp) => {
    const lower_names = Object.entries(resp['customers']).map(([name, cid]) => [name.toLowerCase(), cid]);
    const lower_customers = Object.fromEntries(lower_names);
    const ordered_names = Object.entries(lower_customers)
      .sort()
      .map((customer) => [capitalize_string(customer[0]), customer[1]]);
    const ordered_customers = Object.fromEntries(ordered_names);
    render_customers(ordered_customers);
  });
  show_content(admin_page);
  init_user_navbar();
  return true;
}

function get_customers_list() {
  const customers = rpc.list_customers().then((resp) => {
    return resp['customers'];
  });
  return customers;
}

export { show_customers, get_customers_list };
