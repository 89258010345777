const report_regexp = /[0-9demo|_mig]+\/[0-9]+R/;
const genome_regexp = /[0-9demo|_mig]+\/[0-9]+G/;
const new_document_regexp = /.*def.*Style.*/;

export function is_report_selected(hash = location.hash) {
  return report_regexp.test(hash);
}

export function is_genome_selected(hash = location.hash) {
  return genome_regexp.test(hash);
}

export function is_new_document_creation(hash = location.hash) {
  return new_document_regexp.test(hash);
}

function is_demo_db(hash = location.hash) {
  return hash.startsWith('#demo');
}

function get_object_values(obj) {
  return Object.keys(obj).map((key) => obj[key]);
}

export function get_selected_db(hash = location.hash, user_databases = {}) {
  const db_id = get_selected_db_id_clean(hash);
  const db_namess = Object.keys(user_databases);
  return db_namess.find((dn_name) => user_databases[dn_name] === db_id) || '';
}

export function get_selected_db_id(hash = location.hash) {
  let db_with_prepended_hash = undefined;
  if (is_report_selected(hash) || is_genome_selected(hash)) {
    db_with_prepended_hash = hash.split('?', 1)[0];
    if (globalThis.last_db_cookie_name) {
      globalThis.set_cookie(globalThis.last_db_cookie_name, db_with_prepended_hash.replace('#', ''));
    }
  }

  if (!db_with_prepended_hash) {
    db_with_prepended_hash = `#${globalThis.get_cookie(globalThis.last_db_cookie_name) || ''}`;
  }

  return db_with_prepended_hash;
}

export function get_selected_db_id_clean(hash = location.hash) {
  return remove_trailing_slash(get_selected_db_id(hash).replace('#', ''));
}

function remove_trailing_slash(hash) {
  if (hash.endsWith('/')) {
    return remove_trailing_slash(hash.slice(0, -1));
  }
  return hash;
}

export function get_hash(user_databases, current_hash = location.hash) {
  if (current_hash) {
    return current_hash;
  }

  const default_database = Object.keys(user_databases)[0];
  let default_database_id = user_databases[default_database];
  return globalThis.get_cookie(globalThis.last_db_cookie_name) || default_database_id;
}
