import { initialize_db_list } from '../glaze/document';
import { create_new_user_from_admin } from './user';
import { import_init } from '../ligo/import_files';
import { show_balance, show_cluster, show_unregistered } from '../ligo/admin';
import { show_admin_menu } from './ui';
import { is_genome_selected, is_new_document_creation, is_report_selected } from './hash';
import { display_genome, display_reports, display_user, display_usercompany, display_new_document_creation, display_mdf } from './display';
import { renew_server_license } from './renew_server_license';
import { hide_visible_toast, show_toast_from_cookie } from './toast';
import { display_support_page } from './support';
import { show_customers } from './customers_page';

let disable_pop_state_handler = false;

const routes = [
  {
    name: 'mdf',
    predicate: () => location.hash.startsWith('#mdf'),
    action: display_mdf,
  },
  {
    name: 'logout',
    predicate: () => location.hash.startsWith('#logout'),
    action: globalThis.do_logout,
  },
  {
    name: 'create_new_document',
    predicate: is_new_document_creation,
    action: display_new_document_creation,
  },
  {
    name: 'reports',
    predicate: is_report_selected,
    action: display_reports,
  },
  {
    name: 'genome',
    predicate: is_genome_selected,
    action: display_genome,
  },
  {
    name: 'company',
    predicate: () => location.hash === '#company',
    action: display_usercompany,
  },
  {
    name: 'user',
    predicate: () => location.hash === '#user',
    action: display_user,
  },
  {
    name: 'import',
    predicate: () => location.hash.startsWith('#import'),
    action: import_init,
  },
  {
    name: 'unregistered',
    predicate: () => location.hash.startsWith('#unregistered'),
    action: show_unregistered,
  },
  {
    name: 'cluster',
    predicate: () => location.hash.startsWith('#cluster'),
    action: show_cluster,
  },
  {
    name: 'balance',
    predicate: () => location.hash.startsWith('#balance'),
    action: show_balance,
  },
  {
    name: 'create new user',
    predicate: () => location.hash.startsWith('#newuser'),
    action: create_new_user_from_admin,
  },
  {
    name: 'renew server license',
    predicate: () => location.hash.startsWith('#renew_server_license'),
    action: renew_server_license,
  },
  {
    name: 'support',
    predicate: () => location.hash.startsWith('#support'),
    action: display_support_page,
  },
  {
    name: 'customers',
    predicate: () => location.hash.startsWith('#customers'),
    action: show_customers,
  },
  {
    name: 'help',
    predicate: () => location.hash.startsWith('#help'),
    action: () => (location.href = location.hash.slice(1)),
  },
];

function set_admin_menu() {
  if (globalThis.user_data.info['customer_id'] === 2) {
    show_admin_menu();
  }
}

export function on_route_changed(
  event,
  r = routes,
  after_actions = [() => initialize_db_list(), () => set_admin_menu(), () => show_toast_from_cookie()]
) {
  document.title = 'Ceramics Genome';
  document.getElementById('expenses_div')?.remove();
  hide_visible_toast();

  const found_route = r.find((route) => route.predicate());
  if (found_route) {
    console.log('Executing route: ', found_route.name);
    found_route.action();
  } else {
    console.log('No route found');
    set_cookie(globalThis.last_db_cookie_name, '');
    globalThis.location.href = '';
    return;
  }

  after_actions.forEach((action) => action());
}

function move_forward_disabling_popstate() {
  disable_pop_state_handler = true;
  globalThis.history.forward();
}

function popstate_handler() {
  if (disable_pop_state_handler) {
    disable_pop_state_handler = false;
    return;
  }
  if (!globalThis.discard_dirty_state()) {
    move_forward_disabling_popstate();
    return;
  }

  on_route_changed(undefined, routes);
}

globalThis.removeEventListener('popstate', popstate_handler);
globalThis.addEventListener('popstate', popstate_handler);
globalThis.removeEventListener('route_changed', on_route_changed);
globalThis.addEventListener('route_changed', on_route_changed);
