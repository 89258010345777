import { empty_element, hide_element_with_opacity_transition, show_element_with_opacity_transition } from '../common/ui';
import { rpc } from '../common/crystal_api/rpc';
import { get_vars } from '../common/url';
import { setUrlVars } from '../common/utils';
import { show_search_form_and_table } from './show_search_form_and_table';
import { get_selected_db_id } from '../common/hash';
import { hide_visible_toast, show_ephemeral_toast } from '../common/toast';

function build_conf(currents, styles) {
  console.log('build_conf');
  let key = currents['mtype'] || currents['class_name'] || styles['_id'].slice(1, -2);
  const material_class = globalThis.material_classes[key];
  if (material_class) {
    return new material_class(currents, styles);
  } else {
    return new globalThis.Conf(currents, styles);
  }
}

export function get_style(doc, elem, styles) {
  console.log('get style');
  if (styles.error && (!styles.class_name || !styles.class_name.current.startsWith('Model'))) {
    show_ephemeral_toast(_('missing-document'), 'Error');
    show_search_form_and_table(globalThis.show_materials_table);
    return;
  }
  const conf = build_conf(doc, styles);
  delete globalThis.current_document_conf;
  globalThis.current_document_conf = conf;
  empty_element(elem);
  conf.view(elem);
  show_element_with_opacity_transition(elem.id);
}

async function show_dict_properties(target_elem, doc, styles = {}) {
  console.log('show_dict_properties', styles);
  if (doc.error) {
    show_ephemeral_toast(_('missing-document'), 'Error');
    show_search_form_and_table(globalThis.show_materials_table);
    return;
  }
  if (target_elem && target_elem.innerHTML !== '') {
    hide_element_with_opacity_transition(target_elem.id);
  }

  hide_visible_toast();
  let doc_styles = {};
  if (Object.keys(styles).length) {
    doc_styles = styles;
  } else {
    doc_styles = await rpc.doc({ uid: doc['style'][0] });
  }
  return get_style(doc, target_elem, doc_styles);
}

function show_dict_properties_pushing_state(target, currents, styles = {}) {
  show_dict_properties(target, currents, styles);

  const params = get_vars();
  params['uid'] = currents['_id'];
  const path = `${get_selected_db_id()}?${setUrlVars(params)}`;
  globalThis.history.pushState(path, 'Show', path);
}

export { show_dict_properties, show_dict_properties_pushing_state, build_conf };
