var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id='storyboard_shapes_dialog' class='relative z-10' role='dialog'>\n  <div\n    x-transition:enter='ease-out duration-1000'\n    x-transition:enter-start='opacity-0'\n    x-transition:enter-end='opacity-100'\n    x-transition:leave='ease-in duration-200'\n    x-transition:leave-start='opacity-100'\n    x-transition:leave-end='opacity-0'\n    class='inset-0 bg-gray-500 bg-opacity-75 transition-opacity fixed'\n  >\n  </div>\n\n  <div class='fixed inset-0 z-10 overflow-y-auto'>\n    <div class='flex min-h-full justify-center text-center items-center p-0' x-data=\"{new_shape_value:'None'}\">\n      <div\n        x-transition:enter='ease-out duration-300'\n        x-transition:enter-start='opacity-0 translate-y-4 translate-y-0 scale-95'\n        x-transition:enter-end='opacity-100 translate-y-0 scale-100'\n        x-transition:leave='ease-in duration-200'\n        x-transition:leave-start='opacity-100 translate-y-0 scale-100'\n        x-transition:leave-end='opacity-0 translate-y-4 translate-y-0 scale-95'\n        class='transform overflow-hidden rounded-sm bg-white text-left shadow-xl transition-all my-8 w-full max-w-lg relative'\n      >\n        <div class='bg-gray-100 py-3 flex px-6'>\n          <h3 data-cy='sample-shapes-dialog-title' class='text-lg font-medium leading-6 !text-elsred' x-text='_(\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":25,"column":113},"end":{"line":25,"column":122}}}) : helper)))
    + "\")'>\n          </h3>\n        </div>\n        <div class='bg-white bg-opacity-30'>\n          <div class='items-start'>\n            <div class='p-4'>\n              <p class='border-2 border-gray-400' x-effect=\"$dispatch('storyboard_shapes_dialog_ready',{'dialog':$el})\"></p>\n              <label x-text=\"_('Set image as:')\" for='update_sample_shapes_image'></label>\n              <select\n                id='update_sample_shapes_image'\n                class='rounded-sm py-0 focus:!ring-elsred focus:!border-elsred'\n                x-data='{ available_shapes: "
    + alias4(((helper = (helper = lookupProperty(helpers,"available_shapes") || (depth0 != null ? lookupProperty(depth0,"available_shapes") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"available_shapes","hash":{},"data":data,"loc":{"start":{"line":36,"column":44},"end":{"line":36,"column":64}}}) : helper)))
    + " }'\n                data-temp='"
    + alias4(((helper = (helper = lookupProperty(helpers,"temp") || (depth0 != null ? lookupProperty(depth0,"temp") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"temp","hash":{},"data":data,"loc":{"start":{"line":37,"column":27},"end":{"line":37,"column":35}}}) : helper)))
    + "'\n                data-time='"
    + alias4(((helper = (helper = lookupProperty(helpers,"time") || (depth0 != null ? lookupProperty(depth0,"time") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"time","hash":{},"data":data,"loc":{"start":{"line":38,"column":27},"end":{"line":38,"column":35}}}) : helper)))
    + "'\n                data-smp_idx='"
    + alias4(((helper = (helper = lookupProperty(helpers,"smp_idx") || (depth0 != null ? lookupProperty(depth0,"smp_idx") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"smp_idx","hash":{},"data":data,"loc":{"start":{"line":39,"column":30},"end":{"line":39,"column":41}}}) : helper)))
    + "'\n                data-x_prof='"
    + alias4(((helper = (helper = lookupProperty(helpers,"x_prof") || (depth0 != null ? lookupProperty(depth0,"x_prof") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"x_prof","hash":{},"data":data,"loc":{"start":{"line":40,"column":29},"end":{"line":40,"column":39}}}) : helper)))
    + "'\n                data-y_prof='"
    + alias4(((helper = (helper = lookupProperty(helpers,"y_prof") || (depth0 != null ? lookupProperty(depth0,"y_prof") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"y_prof","hash":{},"data":data,"loc":{"start":{"line":41,"column":29},"end":{"line":41,"column":39}}}) : helper)))
    + "'\n                @change='new_shape_value = $el.value'\n              >\n                <template x-for='val in available_shapes'>\n                  <option x-text='val' :value='val'></option>\n                </template>\n              </select>\n            </div>\n          </div>\n        </div>\n        <div class='px-4 py-3 space-x-2 float-right'>\n          <button\n            data-cy='confirm-edit-shape'\n            type='button'\n            :class='get_filled_button_classes()'\n            @click='() => {set_new_shape_image();remove_element(\"storyboard_shapes_dialog\")}'\n            x-text='_(\"Confirm\")'\n            x-bind:disabled='new_shape_value === \"None\"'\n          >\n          </button>\n          <button type='button' :class='get_outline_button_classes()' @click='remove_element(\"storyboard_shapes_dialog\")' x-text='_(\"Close\")'>\n          </button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});