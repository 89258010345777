import _, { last } from 'underscore';

const changes = (object, base) => {
  const inner_changes = (object, base) =>
    _.pick(
      _.mapObject(object, (value, key) =>
        !_.isEqual(value, base[key]) ? (_.isObject(value) && _.isObject(base[key]) ? inner_changes(value, base[key]) : value) : null
      ),
      (value) => value !== null
    );
  return inner_changes(object, base);
};

globalThis.changes = changes;

export class ChangeLog {
  constructor(options) {
    this.all_values_history = {};
    Object.keys(options).forEach((k) => {
      const style = Object.assign({}, options[k].style);
      style.current = options[k].current;
      this.all_values_history[k] = [style];
    });

    this.changed_entries = {};
    this.initial_values = structuredClone(this.all_values_history);
    this.deleted_values = [];
  }

  add_style(style) {
    let handle = style.handle;
    if (handle.startsWith('@')) {
      handle = handle.substr(1);
    }
    if (!this.all_values_history[handle]) {
      this.all_values_history[handle] = [];
      this.initial_values[handle] = [undefined];
    }
    this.all_values_history[handle].push(style);
    this.changed_entries[handle] = !_.isEqual(this.get_last_style_of(handle), this.initial_values[handle][0]);
  }

  add_value(value, handle, style = {}) {
    if (!this.all_values_history[handle]) {
      this.all_values_history[handle] = [style];
      this.initial_values[handle] = [undefined];
    }
    const changelog_style = structuredClone(this.get_last_style_of(handle));
    changelog_style.current = value;
    this.add_style(changelog_style);
  }

  remove_value(handle) {
    this.deleted_values.push(handle);
  }

  modify_style(fragment, handle) {
    const style = structuredClone(this.get_last_style_of(handle));
    Object.assign(style, fragment);
    this.add_style(style);
  }

  undo(handle) {
    const first_value = this.get_first_style_of(handle);
    this.changed_entries[handle] = !_.isEqual(first_value, this.initial_values[handle][0]);
    this.all_values_history[handle] = [first_value];
  }

  commit(handle) {
    this.all_values_history[handle] = [this.get_last_style_of(handle)];
  }

  restore() {
    this.all_values_history = structuredClone(this.initial_values);
    const changed_entries = this.changed_entries;
    this.changed_entries = {};
    const original_changed_values = {};
    Object.keys(changed_entries).forEach((key) => {
      original_changed_values[key] = this.initial_values[key][0];
    });
    return original_changed_values;
  }

  restore_handle(handle) {
    this.all_values_history[handle] = structuredClone(this.initial_values[handle]);
    if (this.deleted_values.includes(handle)) {
      const handle_index = this.deleted_values.indexOf(handle);
      this.deleted_values.splice(handle_index, 1);
    }
    return;
  }

  is_dirty() {
    return Object.keys(this.changed_entries).filter((key) => this.changed_entries[key]).length > 0;
  }

  get_changes(keys_to_ignore = []) {
    const that = this;
    return Object.keys(this.all_values_history).reduce((result, key) => {
      const last_option_value = that.get_last_style_of(key);
      const original_option_value = that.initial_values[key][0];
      if (!original_option_value && last_option_value) {
        result[key] = ['__undefined_option__', last_option_value.current, last_option_value];
      } else if (
        (!keys_to_ignore.includes(key) && !_.isEqual(original_option_value, last_option_value) && Object.keys(this.changed_entries).includes(key)) ||
        this.deleted_values.includes(key)
      ) {
        const diff = changes(last_option_value, original_option_value);
        delete diff.current;
        result[key] = [original_option_value.current, last_option_value.current, diff];
      }
      return result;
    }, {});
  }

  clean_all() {
    this.changed_entries = {};
    return true;
  }

  get_last_style_of(handle) {
    const handle_values = this.all_values_history[handle] || [];
    if (handle_values.length > 1) {
      return handle_values[handle_values.length - 1];
    }
    return handle_values[0];
  }

  get_last_value_of(handle) {
    return this.get_last_style_of(handle)?.current;
  }

  get_first_style_of(handle) {
    return this.all_values_history[handle][0];
  }

  get_first_value_of(handle) {
    return this.get_first_style_of(handle)?.current;
  }
}
