import * as echarts from 'echarts';

function show_overlay_plot(title, name) {
  const input_desc = {
    title: title,
    option_name: name,
  };
  const pie_plot_template = require(`../../view/document/pie_plot_overlay.handlebars`);
  const pie_plot_html = pie_plot_template(input_desc);
  const node = document.createElement('div');
  node.innerHTML = pie_plot_html;
  document.body.appendChild(node);
}

export function show_column_plot(optiontable, key, title) {
  console.log('plot option table column');
  const plot_title = `${optiontable.name}, column ${title}`;
  show_overlay_plot(plot_title, optiontable.style.name);
  const column_plot = echarts.init(document.getElementById('plot_container'), 'dark');
  const series_data = optiontable.table
    .getData()
    .filter((d) => d['material'] !== 'Date')
    .map((d) => ({ value: d[key], name: `${d['material']}, ${d[key]}%` }));
  const options = { legend: { show: true }, series: { type: 'pie', data: series_data } };
  column_plot.setOption(options);
}

export function show_row_plot(optiontable, group_name) {
  console.log('plot table row');
  const plot_title = `${optiontable.name}, ${group_name} delta`;
  show_overlay_plot(plot_title, optiontable.style.name);
  const cols = optiontable.table.getColumns();
  const group_columns = [];
  cols.forEach((c) => {
    if (c.getParentColumn() && c.getParentColumn().getDefinition().title === group_name) {
      group_columns.push(c.getDefinition());
    }
  });
  let x_axys_labels = group_columns.map((c) => c['title'].replace(' %', ''));
  const series = [];
  const columns_fields = group_columns.map((c) => c.field);
  const table_data = optiontable.table.getData().filter((d) => d['material'] !== 'Date');
  for (const row of table_data) {
    const data = [];
    for (const f of columns_fields) {
      data.push(row[f]);
    }
    series.push({ type: 'line', data: data, name: row['material'] });
  }
  const row_plot = echarts.init(document.getElementById('plot_container'), 'dark');
  const options = { legend: { show: true }, xAxis: { type: 'category', data: x_axys_labels }, yAxis: { type: 'value' } };
  options['series'] = series;
  row_plot.setOption(options);
}
