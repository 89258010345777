var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  id='shapes_div'\n  x-data='{shapes_slider_open:get_parameter(\"shapes_slider_open\")}'\n  x-init=\"()=>{\n    $nextTick(() => shapes_slider_open && lambda_shapes("
    + alias4(((helper = (helper = lookupProperty(helpers,"report_currents") || (depth0 != null ? lookupProperty(depth0,"report_currents") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"report_currents","hash":{},"data":data,"loc":{"start":{"line":5,"column":56},"end":{"line":5,"column":75}}}) : helper)))
    + "))\n    $watch('shapes_slider_open', v => {set_query_param('shapes_slider_open', v);lambda_shapes("
    + alias4(((helper = (helper = lookupProperty(helpers,"report_currents") || (depth0 != null ? lookupProperty(depth0,"report_currents") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"report_currents","hash":{},"data":data,"loc":{"start":{"line":6,"column":94},"end":{"line":6,"column":113}}}) : helper)))
    + ")});\n  }\"\n>\n  <h2 class='clear-both font-bold py-2' x-text='_(\"Samples images\")'></h2>\n  <button\n    id='btn_shapes'\n    @click='shapes_slider_open = !shapes_slider_open'\n    :class='get_outline_button_classes()'\n    data-cy='load-shapes'\n    x-text='shapes_slider_open ? _(\"Hide\") : _(\"Show\")'\n    type='button'\n  >\n  </button>\n  <h3 class='clear-both font-bold py-2' x-text='_(\"Characteristic shapes\")' x-show='shapes_slider_open'></h3>\n  <div id='"
    + alias4(((helper = (helper = lookupProperty(helpers,"report_id") || (depth0 != null ? lookupProperty(depth0,"report_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"report_id","hash":{},"data":data,"loc":{"start":{"line":20,"column":11},"end":{"line":20,"column":24}}}) : helper)))
    + "_shapes' class='w-full' x-show='shapes_slider_open' data-cy='samples-characteristic-shapes'></div>\n  <div id='"
    + alias4(((helper = (helper = lookupProperty(helpers,"report_id") || (depth0 != null ? lookupProperty(depth0,"report_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"report_id","hash":{},"data":data,"loc":{"start":{"line":21,"column":11},"end":{"line":21,"column":24}}}) : helper)))
    + "_storyboard' class='pt-2' x-show='shapes_slider_open' data-cy='samples-storyboard'>\n    <div>\n      <h3 x-text=\"_('Storyboard')\" class='clear-both font-bold pt-2'></h3>\n      <input\n        data-cy=\"storyboard_slider\"\n        id='"
    + alias4(((helper = (helper = lookupProperty(helpers,"report_id") || (depth0 != null ? lookupProperty(depth0,"report_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"report_id","hash":{},"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":26,"column":25}}}) : helper)))
    + "_input_slider'\n        type='range'\n        min='0'\n        value='0'\n        step='60'\n        max='"
    + alias4(((helper = (helper = lookupProperty(helpers,"report_elapsed") || (depth0 != null ? lookupProperty(depth0,"report_elapsed") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"report_elapsed","hash":{},"data":data,"loc":{"start":{"line":31,"column":13},"end":{"line":31,"column":31}}}) : helper)))
    + "'\n        class='w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer'\n        data-doc_id='"
    + alias4(((helper = (helper = lookupProperty(helpers,"report_uid") || (depth0 != null ? lookupProperty(depth0,"report_uid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"report_uid","hash":{},"data":data,"loc":{"start":{"line":33,"column":21},"end":{"line":33,"column":35}}}) : helper)))
    + "'\n        :title='_(\"Sample deformation during test\")'\n        @input.debounce='storyboard_update(\""
    + alias4(((helper = (helper = lookupProperty(helpers,"report_id") || (depth0 != null ? lookupProperty(depth0,"report_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"report_id","hash":{},"data":data,"loc":{"start":{"line":35,"column":44},"end":{"line":35,"column":57}}}) : helper)))
    + "\")'\n      />\n    </div>\n  </div>\n</div>";
},"useData":true});