import { dateToISO } from '../../common/ui';
import { Option } from './option';

class OptionDateTime extends Option {
  get_current_widget_id() {
    return `${this.uid}_datetime_widget`;
  }

  current_from_user() {
    if (this.input_edit && this.uid.includes('def:Style#SearchGenome#0')) {
      var timestamp_date = 0;
      var date = this.input_edit.valueAsDate;
      if (date) {
        timestamp_date = date.getTime() / 1000;
      }
      return timestamp_date;
    } else {
      return this.current;
    }
  }

  template_input_edit_desc() {
    const edit_option_desc = {
      current_option_uuid: this.uuid,
      option_id: this.uid,
      current: dateToISO(new Date(this.current * 1000)),
    };
    return [edit_option_desc, 'option/edit_option_datetime'];
  }

  value_to_user(value) {
    const input_element = document.getElementById(`${this.uid}_input`);
    if (input_element) {
      input_element.valueAsNumber = dateToISO(new Date(value * 1000));
    }
    if (value == 0 && !this.uid.startsWith('def:Style#SearchGenome#0')) {
      return '-';
    }
    const date = new Date(value * 1000)
    return date.valueOf() ? date.toLocaleString() : value;
  }
}

globalThis.option_types['DateTime'] = OptionDateTime;
