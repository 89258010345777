import { build_option, Option } from './option';
import { closeNav } from '../../common/look';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { show_column_plot, show_row_plot } from './option_table_plot';
import * as XLSX from 'xlsx-js-style';
import { as_number_safe, format_number, get_document_hash, get_uid_from_row_safe, get_unit } from '../../common/utils';
import { hide_element, remove_element, set_animate_pulse_classes, show_element } from '../../common/ui';
import { ajaxRequestFunc } from '../../common/ajax_request_func';
import { get_color_coding_for } from './color_coding';

import { collect } from 'collect.js';
import { show_ephemeral_toast, show_sticky_toast } from '../../common/toast';
import { edit_option_overlay_table } from '../../common/tabulator/overlay_table';
import { export_header_style, export_title_style } from '../../common/export_elements';

require('../../common/python_format');

const apply_colormap = (cells) => {
  cells = collect(cells);
  const colors = get_color_coding_for(cells.map((cell) => cell.getValue()));
  cells
    .zip(colors)
    .all()
    .forEach(([cell, color]) => (cell.getElement().style.backgroundColor = color));
};

function get_role_value_span(cell, header, value) {
  const id = cell['_cell']['row']['data'][header.handle + '_id'] || 'default';
  const role_value_span = document.createElement('span');
  let ver = 'Latest';
  role_value_span.innerHTML = _(value);
  if (id.includes('ver#')) {
    ver = id.split('#').at(-1);
    role_value_span.innerHTML += ', version: <b>' + ver + '</b>';
  }
  role_value_span.classList.add('text-elsred');
  return role_value_span;
}

function get_boolean(value, id) {
  if (value) {
    return `<span data-cy="table_boolean_${id}">&#10003</span>`;
  }
  return `<span data-cy="table_boolean_${id}">&#10799</span>`;
}

class OptionTable extends Option {
  constructor(current, style, conf) {
    super(current, style, conf);
    this.row_groups = {};
    let r = this.build_header(this.style['row_header'], conf);
    this.row_header = r[0];
    const h = this.style['header'];
    r = this.build_header(h, conf);
    this.header = r[0];
    this.fields = r[1];
    this.updatable = this.style.bookmark !== undefined && this.conf.uid !== 'new_';
  }

  update_view(is_edit_mode) {}

  set_edit_mode(is_edit_mode) {
    super.set_edit_mode(is_edit_mode);
    const columns = this.table?.getColumns() || [];
    const new_column_definitions = columns
      .map((c) => c._column.definition)
      .map((definition) => {
        definition.editable = is_edit_mode && definition.title.toLowerCase().trim() !== 'material';
        return definition;
      });

    this.table?.setColumns(new_column_definitions);
  }

  is_edited_in_overlay() {
    return true;
  }

  has_grid_class() {
    return !this.show_current_table();
  }

  should_show_content() {
    return false;
  }

  justify_current() {
    if (this.current.length) {
      return 'justify-self-stretch';
    }
    return 'justify-self-end';
  }

  inline_option() {
    if (!this.current.length) {
      return 'inline-block';
    }
    return '';
  }

  show_current_table() {
    const is_referees_or_versions = this.handle === 'referees' || this.handle === 'versions';
    return this.current.length > 0 && !is_referees_or_versions;
  }

  get_template_name() {
    const is_referees_or_versions = this.handle === 'referees' || this.handle === 'versions';

    if (!is_referees_or_versions && this.current.length < 1) {
      if (!this.readonly) {
        return `create_table`;
      } else {
        return 'empty_table';
      }
    } else if (is_referees_or_versions) {
      return `load_table`;
    } else {
      return 'table';
    }
  }

  get_header(row, column) {
    if (this.row_header && this.row_header.length) {
      return this.row_header[row] || this.header[column] || { type: '', attr: [] };
    }
    return this.header[column];
  }

  get_value(row, column) {
    let value;
    if (this.new_current.length && this.new_current[row] && this.new_current[row][column] !== undefined) {
      return this.new_current[row][column];
    } else if (this.current.length && this.current[row] && this.current[row][column] !== undefined) {
      return this.current[row][column];
    } else {
      const h = this.get_header(row, column);
      return h['style']['factory_default'];
    }
  }

  build_header(header, conf) {
    const h = [];
    const fields = [];
    if (!header) {
      return [h, fields];
    }
    for (let i = 0; i < header.length; i++) {
      h.push(build_option(null, header[i], conf));
      fields.push(header[i]['handle']);
    }
    return [h, fields];
  }

  download_table() {
    const doc_name = this.conf['currents']['name'].replace(' ', '_');
    this.table.download('xlsx', `${doc_name}_${this.handle}.xlsx`, {
      sheetName: this.name.substring(0, 30),
      writeOptions: {
        bookType: 'xlsx',
      },
    });
    return true;
  }

  get_option_class() {
    if (this.show_current_table()) {
      return 'none';
    }
    return '';
  }

  current_to_user(should_show_unit = true) {
    return this.value_to_user(this.current, should_show_unit);
  }

  value_to_user(value) {
    // Convert tabular data into an array of maps using the header
    // option conversion mechanics
    const data = [];
    value = value || [];
    for (let i = 0; i < value.length; i++) {
      const d = {};
      for (let j = 0; j < this.header.length; j++) {
        d['original_row'] = i;
        const h = this.get_header(i, j);
        d[this.header[j].handle] = h.value_to_user(value[i][j], false);
        if (this.header[j].type === 'Role') {
          d[h.handle + '_id'] = value[i][j][0];
        }
      }
      if (this.row_header.length) {
        d['row_header'] = this.row_header[i].name;
      }
      if (this.row_groups && this.handle === 'properties') {
        for (const [k, v] of Object.entries(this.row_groups)) {
          for (let pn of v) {
            if (pn === value[i][0]) {
              d['section'] = _(k);
            }
          }
        }
        if (!d['section']) {
          d['section'] = 'Hidden';
        }
      }
      data.push(d);
    }
    if (data.length === 0) {
      data.push(this.table_new_row()[1]);
      this.new_current = [];
    }
    return data;
  }

  value_from_user(value, old = null) {
    return value;
  }

  current_from_user() {
    if (this.uid.startsWith('def:Style#SearchGenome#0')) {
      return this.current;
    }
    return this.value_from_user(this.new_current || this.current);
  }

  async apply_changes(should_refresh_view = true) {
    if (this.value_error) {
      show_sticky_toast(this.value_error_message, 'Error');
      return;
    }

    const current_empty = !this.new_current.length || (this.new_current[0][0] === 'default' && this.header[0]['type'] === 'Role');
    const is_searchGenome = this.conf && this.conf.class_name === 'SearchGenome';
    const current_changed = this.current.toString() != this.new_current.toString();
    const set_to_changelog = (current_empty || current_changed) && !is_searchGenome;

    if (set_to_changelog) {
      this.conf.log.add_value(this.new_current, this.handle);
    }
    if ((current_empty || current_changed) && !is_searchGenome) {
      this.conf.log.add_value(this.new_current, this.handle);
      this.conf.show_action_buttons();
    }
    if (current_empty) {
      this.current = this.new_current = [];
    } else if (current_changed) {
      this.current = this.new_current;
    }
    if (this.conf.is_compare && this.handle === 'docs') {
      this.conf.options['target_type'].disable_chooser(this.current);
    }
    this.dispatchEvent(new Event('OptionChanged'));
    should_refresh_view && this.refresh_view();
    this.conf.log.commit(this.handle);
    const option_label = await globalThis.getElementByIdWithRetry(`${this.uid}_label`);
    option_label && set_animate_pulse_classes(option_label);
  }

  refresh_view() {
    this.closeNav();
    this.new_current = this.current;
    super.refresh_view();
  }

  template_input_edit_desc() {
    let title_target = this.conf.name;
    if (!title_target) {
      title_target = this.conf.currents.name ? this.conf.currents.name : this.conf.class_name;
    }
    const edit_option_desc = {
      current_option_uuid: this.uuid,
      option_id: this.uid,
      option_handle: this.handle,
      option_name: this.style.name,
      title_target: title_target,
    };
    return [edit_option_desc, 'document/table_edit_overlay'];
  }

  get_current_widget_id() {
    let current_widget_id = `${this.uid}_table_widget`;
    const is_referees_or_versions = this.handle === 'referees' || this.handle === 'versions';
    if (!is_referees_or_versions && this.current.length < 1 && !this.readonly) {
      current_widget_id = `${this.uid}_create_table`;
    }
    if (is_referees_or_versions) {
      current_widget_id = `${this.uid}_load_table`;
    }
    return current_widget_id;
  }

  table_to_overlay() {
    return this.header[0]['type'] === 'Role';
  }

  async edit_option_template(full_editing = false) {
    if (!(await super.edit_option_template(full_editing))) {
      return false;
    }
    const m = document.getElementById('map_');
    if (m) {
      hide_element(m);
    }
    await this.set_edit_table_overlay();
    return true;
  }

  async set_edit_table_overlay() {
    document.getElementById(this.uid).classList.remove('table-row');
    this.nameComment_input_value = document.getElementById('nameComment_input')?.value || '';
    document.getElementById('search_in_home').value = '';
    if (this.table_to_overlay()) {
      hide_element('expenses_div');
      edit_option_overlay_table({}, this.uuid);
    }
  }

  table_to_overlay_title() {
    return `${this.conf.currents.name}, ${this.name}`;
  }

  closeNav() {
    document.getElementById('search_in_home').value = '';
    if (document.getElementById('nameComment_input')) {
      document.getElementById('nameComment_input').value = this.nameComment_input_value || '';
    }
    const p_label = document.getElementById(this.uid);
    p_label.parentNode.insertBefore(p_label, p_label.nextSibling);
    show_element('expenses_div');
    closeNav();
    this.delayed_activate_section();
  }

  table_new_row(copy = false) {
    const headers = this.header;
    const new_row = [];
    const item = {};
    for (let i = 0; i < headers.length; i++) {
      new_row.push(headers[i].style['current']);
      item[headers[i]['handle']] = headers[i].style['current'];
      if (copy) {
        item[headers[i]['handle']] = copy[i];
        new_row[i] = copy[i];
      }
      if (headers[i]['type'] === 'Role') {
        item[headers[i]['handle']] = new_row[i][1];
      }
    }
    return [new_row, item];
  }

  show_err_toast() {
    show_ephemeral_toast(_('edit-mode-table'), 'Info');
  }

  restore() {
    this._in_dest_table = {};
    super.restore();
  }

  delete_row(e, row) {
    const row_index = row.getPosition(true) - 1;
    if (row_index > -1) this.new_current.splice(row_index, 1);
    row.delete();
    this._in_dest_table = {};
    this.apply_changes_on_inline_edit();
  }

  add_row_to_table(e, row, add_row_before) {
    const row_index = row.getPosition(true);
    const curr = this.table_new_row()[0];
    const insert_index = add_row_before ? row_index - 1 : row_index;
    this.new_current.splice(insert_index, 0, curr);
    this.table.addRow({}, add_row_before, row);
    this.apply_changes_on_inline_edit();
  }

  parse_new_data(resp) {
    if (!resp) {
      return [];
    }
    const loc_style = resp['@' + this.handle] || {};
    this.style.bookmark = loc_style.bookmark;
    this.params.bookmark = this.style.bookmark;
    console.log('parse_new_data', this.style.bookmark);
    if (loc_style.header) {
      this.style.header = loc_style.header;
    }
    return [this.value_to_user(resp[this.handle]), this.params];
  }

  no_context_menu() {
    return ['properties'].includes(this.handle);
  }

  define_context_menu(editing = false) {
    if (!editing || this.no_context_menu() || this.readonly) return [];
    let menu = [
      {
        label: _('Delete Row'),
        action: (e, row) => this.delete_row(e, row),
      },
    ];

    if (this.get_header(0, 0) && this.get_header(0, 0)['type'] !== 'Role') {
      menu.push(
        {
          label: _('Add Row Before'),
          action: (e, row) => this.add_row_to_table(e, row, true),
        },
        {
          label: _('Add Row After'),
          action: (e, row) => this.add_row_to_table(e, row, false),
        }
      );
    }
    return menu;
  }

  header_menu(col = false, group = false, group_name = '') {
    if (col && col['handle'] === 'row_header') return [];
    const table_header_menu = this.get_header_menu(group, group_name);
    const column_plot = !group && col && col.attr.includes('ColSum');
    if (column_plot) {
      table_header_menu.push({
        label: _('Pie plot'),
        action: () => show_column_plot(this, col.handle, col.name),
      });
    }
    const row_plot = group && !col;
    if (row_plot) {
      table_header_menu.push({
        label: _('Row plot'),
        action: () => show_row_plot(this, group_name),
      });
    }

    return table_header_menu;
  }

  get_header_menu(get_groups, group_name) {
    const menu = [{ label: _('Download table'), action: () => this.download_table() }];
    const columns = this.table.getColumns(get_groups);
    for (let column of columns) {
      if (group_name) {
        const is_group_column = column.getParentColumn() && column.getParentColumn().getDefinition().title === group_name;
        const is_group = column.getDefinition().columns && column.getDefinition().columns.length;
        if ((column.getDefinition().title !== '_' && is_group_column) || is_group) {
          const check = { false: '+', true: '-' }[column.isVisible()];
          menu.push({
            label: `${check} ${column.getDefinition().title}`,
            action: (event) => this.hide_table_columns(column, false, event),
          });
        }
      } else {
        if (column.getDefinition().title !== '_') {
          const check = { false: '+', true: '-' }[column.isVisible()];
          menu.push({
            label: `${check} ${column.getDefinition().title}`,
            action: (event) => this.hide_table_columns(column, false, event),
          });
        }
      }
    }
    return menu;
  }

  hide_table_columns(column, group = false, e) {
    if (!group) {
      e.stopPropagation();
      column.toggle();
    } else {
      for (let c of column) {
        if (c['_column']['definition']['title'] === group) {
          e.stopPropagation();
          c.toggle();
        }
      }
    }
    this.table.redraw(true);
  }

  cellEdit(cell, onRendered, success, cancel) {
    const rc = get_cell_row_column(cell, this.fields);
    const editor = this.get_header(rc[0], rc[1]);
    if (cell.getColumn().getDefinition().field === 'material') {
      return false;
    }
    if (this.readonly && (!this.style.auto || this.style.auto === 'auto')) {
      return false;
    }
    if (editor.attr.includes('ReadOnly')) {
      show_ephemeral_toast(_('cannot-edit'), 'Info');
      return false;
    }
    const genome_components = this.handle === 'components' && this.conf.class_name === 'SearchGenome';
    let empty_table = !this.new_current.length;
    if (genome_components) empty_table = genome_components && empty_table;
    if (empty_table) {
      show_ephemeral_toast(_('Cannot edit cell on empty rows'), 'Info');
      return false;
    }
    editor.current = this.new_current[rc[0]][rc[1]];
    editor.set_table_input(editor, cell, success, cancel, this);
    onRendered(() => {
      editor.input_edit.focus();
      safe_invoke('select', editor.input_edit);
    });

    editor.input_edit.addEventListener('blur', () => this.successFunc(editor, cell, success, cancel, false));

    editor.input_edit.addEventListener('keyup', (event) => {
      if (event.key === 'Enter') editor.input_edit.dispatchEvent(new Event('blur'));
    });

    return editor.input_edit;
  }

  format(cell) {
    let value = cell.getValue() || '';
    const [row, column] = get_cell_row_column(cell, this.fields);
    const header = this.get_header(row, column);
    const type = header['type'];

    if (header['attr'].includes('ColorMap')) {
      apply_colormap(cell.getColumn().getCells());
    }

    if (type === 'Boolean') {
      return get_boolean(value, `${this.current[row] && this.current[row][0]}`);
    }
    if (['Integer', 'Float'].includes(type)) {
      return format_number(this.get_value(row, column), header.style['precision'] || '.6g');
    }
    if (type === 'Role') {
      return get_role_value_span(cell, header, value);
    }

    return _(value);
  }

  cellValidate(cell, value) {
    if (value.error) {
      this.value_error = true;
      this.value_error_message = value.error;
      return false;
    }
    const rc = get_cell_row_column(cell, this.fields);
    const h = this.get_header(rc[0], rc[1]);
    const ctype = h['type'];
    if (!['Integer', 'Float'].includes(ctype)) {
      return true;
    }
    if (h['min'] !== undefined && value < h['min']) {
      return false;
    }
    return !(h['max'] !== undefined && value > h['max']);
  }

  get_column_visibility(column) {
    let col_visibility = true;
    if (column['handle'] === 'parent_table') col_visibility = false;
    if (column.attr.includes('Hidden')) col_visibility = false;
    if (column.visible === 0) col_visibility = false;
    return col_visibility;
  }

  column_bottom_calc(column) {
    let calc = '';
    const header_attr = column.attr;
    const col_count =
      (header_attr.length && header_attr.includes('ColCount')) ||
      (this.updatable && column['type'] !== 'DateTime') ||
      column['handle'] === 'row_header';
    if (header_attr.length && header_attr.includes('ColSum')) {
      calc = 'sum';
    } else if (col_count) {
      calc = 'count';
    } else if (header_attr.length && header_attr.includes('ColMean')) {
      calc = 'avg';
    }
    return calc;
  }

  is_column_editable(column) {
    const is_header = column['handle'] === 'row_header';
    const is_dateTime = this.updatable && column['type'] !== 'DateTime';
    const compare_visible_column = this.handle === 'properties' && column['handle'] !== 'visible';
    return !(is_header || is_dateTime || compare_visible_column);
  }

  define_columns(editing = false) {
    const header = this.style['header'];
    const columns = [];
    if (this.row_header.length) {
      columns.push({
        title: _('Property'),
        field: 'row_header',
        editable: false,
        frozen: true,
        bottomCalc: 'count',
        formatter: (cell) => _(cell.getValue()),
      });
    }
    if (header)
      for (const col of header) {
        const col_type = col['type'];
        const col_bottom_calc = this.column_bottom_calc(col);
        const unit = get_unit(col['unit']);
        const col_def = {
          title: `${_(col['name'])} ${unit}`.trim(),
          field: col['handle'],
          editable: this.is_column_editable(col),
          headerTooltip: true,
          visible: this.get_column_visibility(col),
          bottomCalc: col_bottom_calc,
          bottomCalcParams: col_bottom_calc === 'sum' ? { precision: 1 } : undefined,
          formatter: (cell) => this.format(cell),
          validator: (cell, value) => this.cellValidate(cell, value),
          headerMenu: () => this.header_menu(col, false, col.columns_group),
          headerFilter: this.updatable && col_type !== 'DateTime' ? 'input' : false,
          group: col.columns_group || undefined,
          tooltip: col['type'] === 'Role',
          editor: (cell, onRendered, success, cancel) => this.cellEdit(cell, onRendered, success, cancel),
          editorParams: { selectContents: true },
        };
        columns.push(col_def);
      }

    const groups = [];

    for (let m of columns) {
      if (m.title === 'Material' || m.title === 'Referee') {
        groups.push({
          title: m.title,
          field: m.field,
          frozen: m.frozen,
        });
      }
    }

    const titles = [];
    if (header) {
      header.forEach((h) => {
        if (h.columns_group && !titles.includes(h.columns_group)) {
          titles.push(h.columns_group);
        }
      });
    }
    for (let col_name of titles) {
      const group = {
        title: col_name,
        headerMenu: () => this.header_menu(false, true, col_name),
      };
      group.columns = [];
      for (let c of columns) {
        if (c.group === col_name) {
          group.columns.push(c);
          delete c.group;
        }
      }
      groups.push(group);
    }
    if (titles.length) {
      return groups;
    }
    columns.forEach((col) => delete col.group);
    return columns;
  }

  calc_properties_groups() {
    if (this.handle !== 'properties' || !this.current) {
      return;
    }
    const rg = {};
    for (let o of this.current) {
      if (!rg[o[3]]) {
        rg[o[3]] = [];
      }
      rg[o[3]].push(o[0]);
    }
    this.row_groups = rg;
  }

  tabulator_cellClick(event, cell) {
    const role_header = this.header.find((h) => h['type'] === 'Role' && h['handle'] === cell.getColumn().getDefinition().field);
    const uid = get_uid_from_row_safe(cell.getRow(), role_header);
    if (uid) {
      globalThis.material_table_should_not_be_filtered = true;
      open(get_document_hash(uid, true));
    }
  }

  show_all_rows() {
    let show_all_current_rows = false;
    const handles = ['result', 'cmp_', 'dst_', 'contributions', 'coefficients'];
    if (handles.some((opt_handle) => this.handle.startsWith(opt_handle))) {
      show_all_current_rows = true;
    }
    return show_all_current_rows;
  }

  get_table_parent_id(parent_id, editing, full_screen) {
    const id = (() => {
      if (editing && !parent_id) {
        return `edit_${this.uid}`;
      }
      if (full_screen) {
        return `view_${this.uid}`;
      }
      if (['versions', 'referees'].includes(this.handle)) {
        return `${this.uid}_load_table_widget`;
      }
      return parent_id || `${this.uid}_table_widget`;
    })();
    return id;
  }

  update_search_params() {
    return {
      handle: this.handle,
      uid: this.conf.uid,
      bookmark: this.style.bookmark,
      ...(!this.handle === 'results' ? { doc: this.conf?.ad_doc() } : {}),
    };
  }

  get_table_options() {
    const limit = this.conf?.currents['limit'] || this.style.limit || 25;
    const options = {
      ajaxURL: globalThis.rpc_config.url,
      ajaxConfig: {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json; charset=utf-8',
        },
      },
      progressiveLoad: 'scroll',
      ajaxParams: function () {
        return {
          cmd: 'get',
          limit: limit,
        };
      },
      ajaxRequestFunc: ajaxRequestFunc((resp) => this.parse_new_data(resp), this.params || {}),
      height: 450,
      placeholder: 'No entries',
    };
    return options;
  }

  do_table(editing = false, force = false, full_screen = false, parent_id, should_apply_changes_on_inline_edit) {
    this.should_apply_changes_on_inline_edit = should_apply_changes_on_inline_edit;
    if (this.current.length < 1 && !editing && !force) {
      return;
    }
    if (this.handle === 'referees' && !force) {
      return;
    }
    if (this.handle === 'properties') {
      this.calc_properties_groups();
    }
    parent_id = this.get_table_parent_id(parent_id, editing, full_screen);
    const parent = document.getElementById(parent_id);

    let tabulator_options = {
      // Prevent tables with many rows from running off the screen
      height: this.current.length > 20 ? '700' : '100%',
      layout: 'fitColumns',
      ...(!this.row_header.length ? { rowContextMenu: this.define_context_menu(editing) } : {}),
    };

    let t_opt1 = {};
    if (full_screen) {
      t_opt1 = { height: 700 };
    }
    if (this.updatable) {
      this.params = this.update_search_params();
      t_opt1 = this.get_table_options();
    }
    tabulator_options = { ...tabulator_options, ...t_opt1 };

    const data = this.current_to_user();
    tabulator_options.columns = this.define_columns(editing);
    if (this.handle === 'properties') {
      tabulator_options.groupBy = 'section';
    }

    if (!this.updatable) {
      tabulator_options.data = data;
    }
    if (this.handle === 'cmp_element') {
      tabulator_options['columns'][0]['editable'] = true;
    }
    this.table = new Tabulator(parent, tabulator_options);

    this.editing = editing;
    this.t_opt = tabulator_options;
    const formulation_section = this.style['section'] && this.style['section'] === 'Formulation';
    if (formulation_section || this.handle === 'target') {
      this.activate_section();
    }
    const that = this;
    this.table.on('cellClick', (event, row) => that.tabulator_cellClick(event, row));
    return this.table;
  }

  destroy_table() {
    this.table.destroy();
    document.getElementById(`${this.uid}_load_table_widget`).style = '';
  }

  view_table_in_overlay() {
    const input_desc = {
      current_option_uuid: this.uuid,
    };
    const table_to_overlay_template = require(`../../view/document/table_view_overlay.handlebars`);
    const table_to_overlay_html = table_to_overlay_template(input_desc);
    const node = document.createElement('div');
    node.innerHTML = table_to_overlay_html;
    document.body.appendChild(node);
    document.getElementById(this.uid).classList.remove('table-row');
  }

  delayed_activate_section() {
    super.activate_section();
    if (!this.table) {
      return;
    }
    setTimeout(this.activate_section, 300);
  }

  activate_section() {
    if (!this.table) {
      return;
    }
    const cols = this.table.getColumns();
    let d = 0;
    let i = 0;
    const t_opt = this.t_opt;
    const editing = this.editing;
    for (let j = 0; j < cols.length; j++) {
      i = j - d;
      if (t_opt && t_opt.columns[j] && t_opt.columns[j]['formatter'] === 'handle') {
        d += 1;
        continue;
      }
      if (this.row_header.length) {
        d += 1;
        continue;
      }
      const header = this.style['header'];
      if (header[i] && header[i].type !== 'Role' && editing) {
        if (cols[j]['_column']['modules']['edit']) {
          cols[j]['_column']['modules']['edit'].check = undefined;
        }
      }
      if (header[i] && header[i].visible === false) {
        cols[j].hide();
      }
    }
  }

  validate_new_current(temp) {
    console.log('validating current');
    let error = undefined;
    for (let row of temp) {
      for (let val of row) {
        if (val.error) {
          error = true;
        }
      }
    }
    if (error) {
      return false;
    }
    if (this.header[0]['style']['type'] !== 'Role') {
      return true;
    }
    const roles = [];
    for (let row of temp) {
      if (roles.includes(row[0][0])) {
        show_ephemeral_toast(`${_('materials-duplicate')}: ${row[0][1]}`, 'Error');
        return false;
      }
      roles.push(row[0][0]);
    }
    let size = temp.length;
    if (roles.length === size) {
      return true;
    }
    if (size > 10) {
      size = 10;
    }
    return true;
  }

  successFunc(editor, cell, success, cancel, should_refresh_view = true) {
    const cur = editor.current_from_user();
    const [row, column] = get_cell_row_column(cell, this.fields);
    const temp = structuredClone(this.new_current);
    console.log('success Func');
    temp[row][column] = cur;
    if (this.validate_new_current(temp)) {
      this.new_current = temp;
      const cur_p = this.new_current[row][column];
      if (['Float', 'Integer'].includes(editor['type'])) {
        this.table_numbers_successFunc(editor, cur_p, cancel, success, [row, column]);
      } else if (editor['handle'] === 'where') {
        const chooser_cur = this.table_chooser_successFunc(editor, success);
        this.new_current[row][column] = chooser_cur;
      } else {
        success(cur_p);
        editor.new_current = cur_p;
      }
      console.log('Validate pass');
    }
    this.apply_changes_on_inline_edit(should_refresh_view);
  }

  apply_changes_on_inline_edit(should_refresh_view) {
    this.should_apply_changes_on_inline_edit && this.apply_changes(should_refresh_view);
  }

  table_numbers_successFunc(editor, cur_p, cancel, success, rc) {
    this.header_min = editor['style']['min'] || 'not set';
    this.header_max = editor['style']['max'] || 'not set';
    if (cur_p === 'None') cur_p = 0;
    if (cur_p < this.header_min || cur_p > this.header_max) {
      this.value_error = true;
      this.value_error_message = `${_('Value is out of range')}, (min:${this.header_min}, max:${this.header_max})`;
      show_sticky_toast(this.value_error_message, 'Error');
      cancel(cur_p);
    } else {
      this.value_error = false;
      this.new_current[rc[0]][rc[1]] = cur_p;
      success(cur_p);
    }
  }

  table_chooser_successFunc(editor, success) {
    const cur = editor.components_current_from_user();
    const options = editor.style.options;
    const values = editor.style.values;
    for (const [i, opt] of Object.entries(options)) {
      if (opt === cur) {
        success(values[i]);
      }
    }
    return cur;
  }

  fill_cell(ws, r) {
    const header_titles = this.header.map((h) => {
      const unit = h['style']['unit'] !== 'None' ? ` (${globalThis.get_symbol(h['style']['unit'])})` : '';
      return { v: _(h['name']) + unit, s: export_header_style() };
    });

    const title_style = export_title_style();
    const title_data = [{ v: _(this.name), s: title_style }].concat(header_titles.map(() => ({ v: '', s: title_style })));

    XLSX.utils.sheet_add_aoa(ws, [title_data], { origin: 'A' + r });
    r = r + 1;
    XLSX.utils.sheet_add_aoa(ws, [header_titles], { origin: 'B' + r });
    r = r + 1;
    for (let data of this.current_to_user()) {
      const row = this.header.map((h) => as_number_safe(data[h['handle']]));
      XLSX.utils.sheet_add_aoa(ws, [row], { origin: 'B' + r });
      if (data['_id'] !== undefined) {
        ws['B' + r].l = { Target: location.origin + get_document_hash(data[h.handle + '_id']) };
      }
      r = r + 1;
    }
    return r;
  }
}

function get_cell_row_column(cell, fields) {
  const r = cell.getRow();
  const ri = r.getData()['original_row'];
  const c = cell.getField();
  const ci = fields.indexOf(c);
  return [ri, ci];
}

globalThis.option_types ||= {};
globalThis.option_types['Table'] = OptionTable;
globalThis.option_types['MultiRole'] = OptionTable;

export { OptionTable, format_number };
