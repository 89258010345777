export function all_document_menu_icons_visibilities(conf) {
  const is_new_document = !!(conf.is_new() && !conf.is_version && !conf.is_search());
  const not_in_comparison = conf.currents.name !== 'Comparison';
  const doctype_is_not_element = conf.currents.mtype !== 'element';
  const viewing_version = !!(conf.is_version && !conf.is_full_edit());
  const clean_document_view = not_in_comparison && !conf.is_dirty() && !is_new_document && !viewing_version;

  return {
    execute: is_new_document || conf.is_dirty() || clean_document_view,
    save: is_new_document,
    cancel_creation: is_new_document,
    compare: viewing_version || clean_document_view,
    export_doc: viewing_version || is_new_document || conf.is_dirty() || clean_document_view,
    save_edits: conf.is_dirty() && !is_new_document,
    cancel_edits: conf.is_dirty() && !is_new_document,
    batch: clean_document_view && doctype_is_not_element,
    style: clean_document_view,
    source: clean_document_view,
    clone: clean_document_view && doctype_is_not_element,
    delete_doc: !viewing_version && ((conf.is_dirty() && !is_new_document) || (clean_document_view && doctype_is_not_element)),
    show_minimize_icon: !!conf.is_split_conf && conf.is_splitted_view,
    show_maximize_icon: !!conf.is_split_conf && !conf.is_splitted_view,
    show_end_fast_comparison: !!conf.is_fast_compare,
    show_save_fast_compare: !!conf.is_fast_compare,
    show_initiate_a_formulation: conf.can_initiate_a_formulation(),
    show_initiate_a_semi: conf.can_initiate_a_semi(),
    show_share_icon: conf.can_share_document(),
  };
}

let last_menu = '';
export function create_menu_document(parent, conf) {
  console.log('Creating document menu icons');
  const current_menu = document.getElementById(`document_${conf.objid}_menu`);
  if (!current_menu) {
    last_menu = '';
  }
  const current_menu_data = JSON.stringify(all_document_menu_icons_visibilities(conf));
  if (last_menu === current_menu_data) {
    return;
  }

  const document_menu = {
    doc_menu_id: `document_${conf.objid}_menu`,
    x_data: current_menu_data,
  };

  last_menu = current_menu_data;
  current_menu?.remove();
  const doc_menu_template = require('../view/document/document_menu.handlebars');
  const doc_menu_html = doc_menu_template(document_menu);

  document.getElementById(parent)?.insertAdjacentHTML('beforeend', doc_menu_html);
}
